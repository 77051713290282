import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/shared/constants.service';
@Component({
  selector: 'cm-simsave',
  templateUrl: './simsave.component.html',
  styleUrls: ['./simsave.component.scss']
})
export class SimsaveComponent implements OnInit {

  boxes:any = [
		{
			icon: "fas fa-route",
			title: "Jornadas",
			text: "Nossas jornadas são organizadas para abordagem e tratamento de contextos clínicos recorrentes, como a parada cardiorrespiratória. Na jornada do ACLS, por exemplo, trazemos todo conteúdo do curso organizado de modo a construir progressivamente o seu entendimento. Nas jornadas você passará por todas as nossas metodologias, proporcionando um aprendizado sólido e rico. Todas as jornadas oferecem certificado de conclusão"
		},
		{
			icon: "fas fa-procedures",
			title: "Simulador",
			text: "Nosso exclusivo simulador, desenvolvido por programadores e profissionais da saúde internos com vasta experiência, permite que o usuário teste a habilidade de tomar condutas como líder de equipe, direcionando a sua atuação, dividindo funções e executando o atendimento do inicio ao fim. Tudo em um ambiente virtual controlado onde a cada passo executado você recebe feedbacks, proporcionando um aprendizado inovador, baseado na prática e que respeita os protocolos mais atuais."
		},
		{
			icon: "fas fa-user-md",
			title: "TalkSave",
			text: "Imagine presenciar um debate entre especialistas sobre um caso instigante. O TalkSave proporciona essa experiência no conforto da sua casa, a qualquer horário. Acompanhe o desenvolvimento do raciocínio clínico em casos multidisciplinares abordados profissionais renomados!"
		},
		{
			icon: "fas fa-comments",
			title: "Fórum de discussão",
			text: "Interaja com colegas e professores, tire dúvidas e compartilhe seu conhecimento. No nosso fórum, além de tudo isso, você poderá fazer pedidos, sugestões e encontrar debates ricos sobre conteúdos de toda plataforma."
		},
		{
			icon: "fas fa-video",
			title: "Videoaulas",
			text: "Temos um gama de conteúdos multidisciplinares baseados em evidencias, aulas rápidas de conteúdos pontuais, sendo uma ferramenta de grande ajuda no o dia a dia, enriquecendo e trazendo segurança para atuar. "
		},
  ]

  constructor() {
    if(Constants.isTerzius){
      window.location.href = "https://www.terzius.com.br/404";
    }
  }

  ngOnInit() {

    if(Constants.isTerzius){
      window.location.href = "https://www.terzius.com.br/404";
    }
  }

  redirectPage(url){

    if(confirm("Você será redirecionado para um página externa. Deseja Continuar?")){
      window.open("https://www.simsave.com.br", "_BLANK");
    }

  }
}
