import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { CoreService } from 'src/app/core/core.service';
import { BlogPost } from 'src/app/interfaces/blog-post';
import { BlogTag } from 'src/app/interfaces/blog-tag';
import { SeoService } from 'src/app/services/seo.service';
import { Constants } from 'src/app/shared/constants.service';
import { WINDOW } from '@ng-toolkit/universal';


@Component({
  selector: 'cm-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlogPostComponent implements OnInit {
  currentUrl: string;
  slug: string;
  post: BlogPost;
  tags: BlogTag[];
  blogRelated: any = [];
  author: any = null;
  banner: string = '';

  constructor(@Inject(WINDOW) private window: Window, 
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _titleService: Title,
    private _metaService: Meta
  ) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.post = this._activatedRoute.snapshot.data.blogData;
      this.blogRelated = this.post.related ? this.post.related : [];
      this.post.author.hasOwnProperty('name') ? this.author = this.post.author : '';

      let setTitle = function(){
        const websiteTitle = Constants.isTerzius ? 'Terzius - ':'Curem - ';
        this._titleService.setTitle(websiteTitle + this.post.title);
        let description = this.post.content.substring(0, 300);
        description = description.replace(/<[^>]+>/g, '').substring(0, 150);
        this._metaService.updateTag({name: 'description', content: description});
      }
      setTimeout(setTitle.bind(this), 100);
    });


    this._getTags();
    this._getBanner();
    this.currentUrl = this.window.location.href;
  }

  // ----- PRIVATE METHODS
  private _getTags(): void {
    this._coreService.getBlogTags().subscribe((response: any) => {
      this.tags = response;
    })
  }

  private _getBanner(): void {
    const params = {page: 'blog'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }
}
