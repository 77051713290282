import { Component, Input } from "@angular/core";

@Component({
  selector: "cm-unit-card",
  templateUrl: "./unit-card.component.html",
  styleUrls: ["./unit-card.component.scss"]
})
export class UnitCardComponent{
  @Input() id: number;
  @Input() name: string;
  @Input() city: string;
  @Input() uf: string;
  @Input() address: string;
  @Input() phones: any;
  @Input() email: string;
  @Input() schedule: string;
  @Input() aditionalInfo: string;
  @Input() imageUrl: string;
}
