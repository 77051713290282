import { Component, OnInit, ViewEncapsulation, Inject } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

import { CoreService } from "src/app/core/core.service";
import { Banner } from "src/app/interfaces/banner";
import { CourseAreas } from "src/app/interfaces/course-areas";
import { Course } from "src/app/interfaces/course";
import { Testimonial } from "src/app/interfaces/testimonial";
import { BlogPost } from "src/app/interfaces/blog-post";
import { SeoService } from "src/app/services/seo.service";
import { WINDOW } from "@ng-toolkit/universal";
import { Constants } from "src/app/shared/constants.service";
import { CartService } from "src/app/services/cart.service";
import Swiper from "swiper";

@Component({
  selector: "cm-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  banners: Banner[] = [];
  areas: CourseAreas[] = [];
  packageCourses: Course[] = [];
  assembleClassInfo: any[] = [];
  nextClasses: Course[] = [];
  testimonials: Testimonial[] = [];
  blogLatest: BlogPost[] = [];
  promoCourses: Course[] = [];
  messageProject: any = [];

  isMobile: boolean;
  currentCityName: string;
  nextClassesIsEmpty: boolean = false;
  packageClassesIsEmpty: boolean = false;

  tempo = {
    diasres: 0,
    horasres: 0,
    minres: 0,
    segres: 0,
  };

  constructor(
    @Inject(WINDOW) private window: Window,
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _seoService: SeoService,
    private _router: Router,
    private _cartService: CartService
  ) {}

  ngOnInit() {
    this.isMobile = this.window.innerWidth < 600;
    this._getLocation();
    this._getBanners();
    this._getCourseAreas();
    this._getPromoCourses();
    this._getCoursesPackages();
    this._getAssembleClassInfo();
    this._getNextClasses();
    this._getTestimonials();
    this._getMessageProject();
    this._cartService.setInitialCartValue();

    this._activatedRoute.url.subscribe((url: any) => {
      let path = "";
      url.map((item) => (path += "/" + item.path));
      path = path.substring(1);
      this._seoService.defineTags(path);
    });

    this.atualizaContador();
  }

  ngAfterViewInit() {
    let slidePerView = 6;
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      slidePerView = 3;
    }

    const swiper = new Swiper(".swiper", {
      loop: true,
      direction: "horizontal",
      slidesPerView: slidePerView,
      spaceBetween: 3,
      autoplay: {
        delay: 5000,
      },
    });
  }

  partners = [
    "../../../assets/santa-casa-campo-grande.jpg",
    "../../../assets/austa-hospital.jpg",
    "../../../assets/dmc-equipamento.png",
    "../../../assets/escola-de-enf-el-shaday.jpg",
    "../../../assets/faculdade-santa-teresa-corumba.jpg",
    "../../../assets/faculdade-unyleya.jpg",
    "../../../assets/hec-hospital-estadual-da-crianca.jpg",
    "../../../assets/hospital-da-crianca.jpg",
    "../../../assets/hospital-dona-helena.png",
    "../../../assets/hospital-sao-joao-batista.jpg",
    "../../../assets/hro-hopistal-regional-do-oeste.jpg",
    "../../../assets/philozon-equipamento.jpg",
    "../../../assets/santa-casa-de-juiz-de-fora.jpg",
    "../../../assets/santa-casa-de-londrina.png",
    "../../../assets/sao-cristovao-saude.jpg",
    "../../../assets/uem-universidade-estadual-de-maringa.jpg",
    "../../../assets/uepa-universidade-do-estado-do-para.png",
    "../../../assets/unama-universidade-da-amazonia.jpg",
    "../../../assets/unimed.png",
  ];

  // ----- PUBLIC METHODS
  searchCoursesByArea(id: number): void {
    let params = { course_area_id: id };
    this._router.navigate(["/cursos"], { queryParams: params });
  }

  // ----- PRIVATE METHODS
  private _getBanners(): void {
    this._coreService.getBanners().subscribe((response: any) => {
      this.banners = response;
    });
  }

  private _getCourseAreas(): void {
    this._coreService.getCourseAreas().subscribe((response: any) => {
      this.areas = response;
    });
  }

  private _getCoursesPackages(cityId: number = null): void {
    let params = { only_packages: true };
    cityId ? (params["city_id"] = [cityId]) : "";
    this._coreService.searchCoursesCombo(params).subscribe((response: any) => {
      this.packageCourses = response.data;

      if (cityId != null && response.data.length == 0) {
        this.packageClassesIsEmpty = true;
      }
    });
  }

  private _getAssembleClassInfo(): void {
    this._coreService.getAssembleClassInfo().subscribe((response: any) => {
      this.assembleClassInfo = response.highlights;
    });
  }

  private _getNextClasses(cityId: number = null): void {
    let params = { only_next_classes: true };
    cityId ? (params["city_id"] = [cityId]) : "";
    this._coreService.searchCourses(params).subscribe((response: any) => {
      this.nextClasses = response.data;

      if (cityId != null && response.data.length == 0) {
        this.nextClassesIsEmpty = true;
      }
    });
  }

  private _getPromoCourses(): void {
    const params = { only_promotion: true };
    this._coreService.searchCourses(params).subscribe((response: any) => {
      this.promoCourses = response.data;
    });
  }

  private _getTestimonials(): void {
    const params = { limit: 8 };
    this._coreService.getTestimonials(params).subscribe((response: any) => {
      this.testimonials = response;
    });
  }

  private _getMessageProject(): void {
    const params = { "types[]": ["amigo_peito"] };
    this._coreService.getTexts(params).subscribe((response: any) => {
      this.messageProject = response;
    });
  }

  private _getBlogLatest(): void {
    const params = { latest: true };
    // this._coreService.listBlogPosts(params).subscribe((response: any) => {
    //   this.blogLatest = response.data;
    // })
  }

  private _getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          if (position) {
            const params = {
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            };

            this._coreService.getCities(params).subscribe((response) => {
              this.currentCityName = response.name;
              this._getCoursesPackages(response.id);
              this._getNextClasses(response.id);
            });
          }
        },
        (error: PositionError) => console.log(error)
      );
    }
  }

  atualizaContador() {
    let t = this;
    setInterval(function () {
      let hoje = new Date();
      let dia = hoje.getDate();
      let minutos = hoje.getMinutes();
      let horas = hoje.getHours();
      let segundos = hoje.getSeconds();

      t.tempo.diasres = 28 - dia;
      t.tempo.horasres = t.tempo.diasres * 24 + (23 - horas);
      t.tempo.minres = 59 - minutos;
      t.tempo.segres = 59 - segundos;
    }, 1000);
  }
}
