import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { CoreService } from 'src/app/core/core.service';
import { BlogPost } from 'src/app/interfaces/blog-post';
import { SeoService } from 'src/app/services/seo.service';
import { Constants } from 'src/app/shared/constants.service';

@Component({
  selector: 'cm-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogList: BlogPost[] = [];
  blogCategories: any = [];
  banner: string = '';
  form: FormGroup;
  isSearching: boolean = false;

  totalRecords: number = 0;
  perPageRecords: number = 8;

  constructor(
    private _formBuilder: FormBuilder,
    private _coreService: CoreService,
    private _seoService: SeoService,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    if( Constants.isTerzius){
      window.location.href = "/error";
    }
    this._getBanner();


    this._activatedRoute.queryParams.subscribe(params => {
      const paramsMirror = {...params};
      this._listBlogPosts(paramsMirror);
    })

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });

    this._getBlogCategories();

    this.form = this._formBuilder.group({
      search_text: [null],
      blog_category: [null]
    })
  }

  // ----- PUBLIC METHODS
  findBlogPosts(): void {
    this.isSearching = true;
    const params = this.form.getRawValue();

    this.form.get('search_text').value ? '' : delete params['search_text'];

    if(params['blog_category'] && params['blog_category']['id']){
      params['blog_category'] = params.blog_category.id;
    }else{
      delete params['blog_category'];
    }

    this._listBlogPosts(params);
  }

  paginate(event: any): void {
    let params = {};
    params['page'] = event.page + 1;
    params['per_page'] = this.perPageRecords;

    this._listBlogPosts(params);
  }

  // ----- PRIVATE METHODS
  private _listBlogPosts(params: any = {}): void {
    params['per_page'] = this.perPageRecords;
    this._coreService.listBlogPosts(params)
    .pipe(finalize(() => this.isSearching = false))
    .subscribe((response: any) => {
      this.totalRecords = response.total;
      this.blogList = response.data;
    })
  }

  private _getBlogCategories(): void {
    this._coreService.getBlogCategories().subscribe(response => {
      this.blogCategories = response;
      this.blogCategories.unshift({id: undefined, name: 'Todas'})
    })
  }

  private _getBanner(): void {
    const params = {page: 'blog'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }
}
