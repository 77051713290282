import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Cart, Item, Address } from 'src/app/interfaces/cart';
import { CartService } from 'src/app/services/cart.service';
import { ActivatedRoute } from '@angular/router';
import { ItemShipping } from 'src/app/interfaces/item-shipping';
import { PurchaseResume } from 'src/app/interfaces/purchase-resume';
import { CoreService } from 'src/app/core/core.service';
import { SnotifyService } from 'ng-snotify';
import { RelatedCourse } from 'src/app/interfaces/related-course';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})

export class CartComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private cartService: CartService,
    private coreService: CoreService,
    private snotify: SnotifyService,
  ) { }

  @Input() cep: string = "";
  @Input() shippingType: string = "";
  cartItems: Item[] = [];
  address: Address;
  showCepInput: boolean = true;
  isCepLoading = false;
  voucherApplied = false;
  relatedCourses: RelatedCourse[] = [];
  purchaseResume: PurchaseResume = {
    subtotal: {
      label: "---",
      value: 0,
    },
    total: {
      label: "---",
      value: 0,
    },
    discount: {
      label: "---",
      value: 0,
    },
    shipping: {
      label: "---",
      value: 0,
    }
  };

  enableContinueButton: boolean = false;

  ngOnInit() {

    this.route.queryParams.subscribe(async (params) => {

      if(params['combo']) {
        let response = await this.cartService.getItensCombo(params['combo']).toPromise();
        this.setComboCart(response);
      }

      this.cartService.cartUpdated.subscribe(() => {
        this.getItemsFromCart();
        this.calculateSubTotalValue();
        this.calculateDiscountValue();
        this.calculatePurchaseResumeTotalValue();
      })
  
      this.getAddressFromCart();
      this.getItemsFromCart();
      this.calculateShippingForNewItem();
      this.setCepInputValueWithCartItemCep();
      this.calculateShippingTotalValue();
      this.calculateSubTotalValue();
      this.calculateDiscountValue();
      this.calculatePurchaseResumeTotalValue();
      this.allowContinueButton();
      this.getRelatedCourses();

    });    
    
  }

  setComboCart(data: object) {
    this.cartService.setComboCartValue({ token: data['token'], discount: data['desconto'], qtd: data['itens'].length });
    for(let i in data['itens']) {
      
      let it = data['itens'][i];

      const item:Item = {
        id: it.id,
        image: it.image_url,
        black_friday: false,            
        title: it.title,
        subtitle: it.subtitle,
        date: [it.date],
        place: it.place,
        gt: it.gt,            
        config_tipo_curso_id: it.config_tipo_curso_id,
        result_center_id: it.result_center_id,
        value: it.value,            
        allow_card: it.allow_card,
        allow_billet: it.allow_billet,
        token: it.token
      };
      
      this.cartService.addItemToCart(item);
    }
  }

  calculateShippingForAllItems() {
    this.isCepLoading = true;
    const listItensIds = this.cartItems.map((value) => value.id);

    if (!this.cep) {
      this.isCepLoading = false;
    } else {
      const data: ItemShipping = {
        cep: this.cep,
        courses_ids: listItensIds,
      }

      this.cartService
      .getItemsShippingValue(data)
      .subscribe((response: any) => {
        this.resetShippingTypeSelectedForAllCartItens();
        this.setShippingValueForAllCartItens(response['shipping']);
        this.cartService.updateItemsToCart(this.cartItems);
        this.cartService.updateAddressToCart(response['address']);
        this.setDefaultShippingTotalValueData();
        this.calculateDiscountValue();
        this.calculatePurchaseResumeTotalValue();
        this.allowContinueButton();

        this.isCepLoading = false;
      });
    }
  }

  getAddressFromCart() {
    this.address = this.cartService.getAddressFromCart();
  }

  getItemsFromCart() {
    this.cartItems = this.cartService.getItemsFromCart();
  }

  deleteItemFromCart(id: number) {
    const cartUpdated = this.cartService.deleteItemsFromCart(id);
    this.cartItems = cartUpdated;

    this.calculateSubTotalValue();
    this.calculateShippingTotalValue();
    this.calculateDiscountValue();
    this.calculatePurchaseResumeTotalValue();
    this.allowContinueButton();

    if (this.cartItems.length ==0) this.setDefaultShippingTotalValueData();
  }

  setShippingValueForAllCartItens(data: Object) {

    this.cartItems.map((value) => {
      if (!value.shippingTypes) value.shippingTypes = [];

      if (value.shippingDestiny != this.cep) {
        value.shippingDestiny = this.cep;
        value.shippingTypes = [];

        for(let key in data[value.id]) {
          const shippingInfo = {
            type: key,

            description: {
              ...data[value.id][key],
              shipping_time: data[value.id][key].shipping_time,
              shipping_value_label: this.formatValueLabel(data[value.id][key].shipping_value),
            },
          }

          value.shippingTypes.push(shippingInfo);
        }
      }
    });
  }

  calculateShippingForNewItem() {

    if (this.cartItems.length) {

      if (this.address.shippingDestiny && !this.cartItems[this.cartItems.length-1].shippingDestiny) {

        const shippingDestiny = this.address.shippingDestiny;
        if (shippingDestiny) {
          const newItem = this.cartItems.find((value) => !value.shippingDestiny);

          const data: ItemShipping = {
            cep: shippingDestiny,
            courses_ids: [newItem.id],
          }

          this.cartService
          .getItemsShippingValue(data)
          .subscribe((response: any) => {
            this.setShippingValueForNewCartItem(newItem.id, response['shipping']);
            this.cartService.updateItemsToCart(this.cartItems);
            this.allowContinueButton();
          });
        }
      }
    }
  }

  setShippingValueForNewCartItem(id: number, data: Object) {
    this.cartItems.map((value) => {
      if (value.id == id && !value.shippingDestiny) {
        value.shippingDestiny = this.address.shippingDestiny;
        if (!value.shippingTypes) value.shippingTypes = [];

        for(let key in data[value.id]) {
          const shippingInfo = {
            type: key,
            description: {
              ...data[value.id][key],
              shipping_value_label: this.formatValueLabel(data[value.id][key].shipping_value),
            },
          }

          value.shippingTypes.push(shippingInfo);
        }
      }
    })
  }

  formatValueLabel(data: number): string {
    return data <= 0 ? 'Grátis' :
    Number(data).toLocaleString('pt-br',
    {
      style: 'currency',
      currency: 'BRL',
    });
  }

  setDataShippingSelected(id: number, typeSelected: string, shipping_time: number, shipping_value: number) {
    this.cartItems.forEach((value) => {
      if (id == value.id) {
        value.shippingTypeSelected = typeSelected
        value.shippingTime = shipping_time
        value.shippingValue = shipping_value
      }
    });

    this.cartService.updateItemsToCart(this.cartItems);

    this.calculateShippingTotalValue();
    this.calculateDiscountValue();
    this.calculatePurchaseResumeTotalValue();
    this.allowContinueButton();
  }

  calculateShippingTotalValue() {
    let totalValue = 0;
    let hasShippingTypeNotSelected = false;

    this.cartItems.forEach((value) => {
      const { shippingTypeSelected, shippingTypes } = value;

      if (shippingTypes) {

        if (shippingTypes.length > 0) {

          if (shippingTypeSelected) {

            shippingTypes.map((shippingType) => {
              if (shippingType.type == shippingTypeSelected) totalValue += shippingType.description.shipping_value;
            });
          } else {
            hasShippingTypeNotSelected = true;
          }
        } else {
          if (!value.shippingDestiny) hasShippingTypeNotSelected = true;
        }
      } else {
        hasShippingTypeNotSelected = true;
      }
    });

    if (this.cartItems.length) {
      if (!this.address.shippingDestiny ) {
        this.setDefaultShippingTotalValueData();
      }

      if (!hasShippingTypeNotSelected) {
        this.purchaseResume.shipping.value = totalValue
        this.purchaseResume.shipping.label = this.formatValueLabel(totalValue);
      }
    }
  }

  checkShippingTypeSelected(shippingMethodTypeSelected: string, shippingMethodType: string) {
    return shippingMethodType == shippingMethodTypeSelected;
  }

  setCepInputValueWithCartItemCep() {
    if (this.cartItems.length) {
      this.cep = this.address.shippingDestiny;
    }
  }

  setDefaultShippingTotalValueData() {
    this.purchaseResume.shipping.value = 0;
    this.purchaseResume.shipping.label = "---";
  }

  setDefaultSubTotalValueData() {
    this.purchaseResume.subtotal.value = 0;
    this.purchaseResume.subtotal.label = "---";
  }

  setDefaultTotalValueData() {
    this.purchaseResume.total.value = 0;
    this.purchaseResume.total.label = "---";
  }

  calculateSubTotalValue() {
    const total = this.cartItems.reduce((previous, current) => previous + current.value, 0);

    if (this.cartItems.length > 0) {
      this.purchaseResume.subtotal.label = this.formatValueLabel(total);
      this.purchaseResume.subtotal.value = total;
    } else {
      this.setDefaultSubTotalValueData();
    }
  }

  calculateDiscountValue() {
    let cart = this.cartService.getCart();
    let total = 0;
    for(let i in cart['combo']) {
      total += cart['combo'][i]['discount'];
    }
    this.purchaseResume.discount.label = this.formatValueLabel(total);
    this.purchaseResume.discount.value = total;
  }

  calculatePurchaseResumeTotalValue() {
    const total = this.purchaseResume.subtotal.value + this.purchaseResume.shipping.value - this.purchaseResume.discount.value;
    if (this.cartItems.length > 0) {
      this.purchaseResume.total.label = this.formatValueLabel(total);
      this.purchaseResume.total.value = total;
    } else {
      this.setDefaultTotalValueData();
    }
  }

  handleZipCode(event: any)  {
    let input = event.target;
    input.value = this.zipCodeMask(input.value);
  }

  zipCodeMask(value: string)  {
    if (!value) return "";
    value = value.replace(/\D/g,'');
    value = value.replace(/(\d{5})(\d)/,'$1-$2');
    return value;
  }

  allowContinueButton() {
    if (this.cartItems.length > 0 && this.cep) {

      let itemWithoutShippingTypeSelected = this.cartItems.find((value) => {
        return !value.shippingTypeSelected;
      });

      if (!itemWithoutShippingTypeSelected) {
        this.enableContinueButton = true;
        return;
      }

      if (itemWithoutShippingTypeSelected.shippingTypes && itemWithoutShippingTypeSelected.shippingTypes.length == 0) this.enableContinueButton = true;
    } else {
      this.enableContinueButton = false;
    }
  }

  checkCupom(cupom: string, courseId: number) {
    let ids = [];

    ids.push(courseId);

    const data = {
      coupom: cupom,
      "courses_ids[]": ids,
      payment_method:'',
      installment: 1,
    }

    this.coreService.checkCoupom(data).subscribe({
      next: (res) => {

        if (res.status === 'error') {
          this.snotify.error(res.message, {
            timeout: 3000,
            showProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            position: "centerTop",
          });

          return;
        }

        console.log(res);

        const data = res[0];
        this.changePriceOfItem(courseId, data.id, data.value);
        this.snotify.success('Voucher adicionado com sucesso!', {
          timeout: 3000,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          position: "centerTop",
        });

        this.calculateSubTotalValue();
        this.calculatePurchaseResumeTotalValue();
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

  changePriceOfItem(id: number, coupom_id: number, discount: number) {
    const index = this.cartItems.findIndex((item) => item.id === id);

    if (index === -1) {
      throw new Error(`Item com ID ${id} não encontrado no carrinho.`);
    }

    const updatedItem: Item = {
      ...this.cartItems[index],
      value: this.cartItems[index].value - discount,
      voucherApplied: true, coupom_id
    };

    this.cartItems = [
      ...this.cartItems.slice(0, index),
      updatedItem,
      ...this.cartItems.slice(index + 1)
    ];

    this.cartService.updateItemsToCart(this.cartItems);
  }

  getRelatedCourses() {
    const idMainCourse = this.cartItems[0].id;
    this.coreService.getRelatedCourses(idMainCourse).subscribe({
      next: (res) => {
        this.relatedCourses = res;
      },
      error: (err) => console.log(err),
    })
  }

  formatStringWithEllipsis(text: string) {
    if (text.length <= 34) {
      return text;
    } else {
      return text.slice(0, 34) + "...";
    }
  }

  resetShippingTypeSelectedForAllCartItens () {
    this.cartItems.map((value) => {
      value.shippingTypeSelected = '';
    });
  }
}
