import { Component, OnInit } from '@angular/core';
import { Constants } from '../constants.service';

@Component({
  selector: 'cm-permissao-cookie',
  templateUrl: './permissao-cookie.component.html',
  styleUrls: ['./permissao-cookie.component.scss']
})
export class PermissaoCookieComponent implements OnInit {
  isTerzius: boolean;




  constructor() {

   }

  ngOnInit() {

  Constants.isTerzius?this.isTerzius = true:false;

  let cc = window as any;
    if(!this.isTerzius){
      cc.cookieconsent.initialise({
        palette: {
          popup: {background: 'rgb(0,0,0,0.8)', txt: 'rgb(255,255,255)', link: '#fff'},
          button: {background: 'transparent', border: '#db000f', text: '#db000f'},
        },
        theme: "classic",
        content: {
          message: '<span style="color:#FFF;">Nós usamos cookies para melhorar a funcionalidade e o uso do nosso site, além de contribuir para nossas análises de marketing. Para saber mais sobre o que são cookies, nossa política e como alterar suas configurações de cookies do seu computador, <a style = "color:#ff0000" href="/politica-cookies" target="_blank">clique aqui</a>. Ao continuar a usar este site sem alterar suas configurações, você concorda com o uso de cookies.</span',
          dismiss: 'Continuar',
          link:''
        }
      });
    }else{
      cc.cookieconsent.initialise({
        palette: {
          popup: {background: 'rgb(0,0,0,0.8)', text: '#FFF', link: '#fff'},
          button: {background: 'transparent', border: '#39a870', text: '#39a870'},
        },
        theme: "classic",
        content: {
          message: '<span style="color:#FFF;">Nós usamos cookies para melhorar a funcionalidade e o uso do nosso site, além de contribuir para nossas análises de marketing. Para saber mais sobre o que são cookies, nossa política e como alterar suas configurações de cookies do seu computador, <a style="color:#39a870;" href="/politica-cookies" target="_blank">clique aqui</a>. Ao continuar a usar este site sem alterar suas configurações, você concorda com o uso de cookies.</span',
          dismiss: 'Continuar',
          link:''
        }
      });
    }
  }
}
