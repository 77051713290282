import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import { CoreService } from 'src/app/core/core.service';

@Injectable({providedIn: 'root'})
export class WhoWeEmpowerResolver implements Resolve<Observable<any>> {

  constructor(private _coreService: CoreService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this._coreService.getOurClients();
  }
}
