import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';

import { SnotifyService } from 'ng-snotify';

import { CoreService } from 'src/app/core/core.service';
import { Testimonial } from 'src/app/interfaces/testimonial';
import { SeoService } from 'src/app/services/seo.service';
import { Constants } from 'src/app/shared/constants.service';

@Component({
  selector: 'cm-pos-details',
  templateUrl: './pos-details.component.html',
  styleUrls: ['./pos-details.component.scss']
})
export class PosDetailsComponent implements OnInit {
  slug: string;
  course;
  packageCourses: any = [];
  testimonials: Testimonial[] = [];
  nextClasses: any = [];
  hasCoordinator: boolean = false;

  form: FormGroup;
  displayDialog: boolean = false;
  citiesNames: any;
  private _cities;
  private _selectedCityId;  

  constructor(
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _snotifyService: SnotifyService,
    private _domSanitizer: DomSanitizer,
    private _titleService: Title,
    private _seoService: SeoService
  ) { }

  ngOnInit() {

    this._activatedRoute.params.subscribe(params => {
      this.course = this._activatedRoute.snapshot.data.courseData;
      this._getNextClasses();
      this._getCoursesPackages();
      if(this.course.black_friday == true){
        this.course.payment_conditions = ["Até 1x no Boleto", "Até 1x no Cartão de Crédito"];
      }

      let setTitle = function(){
        const websiteTitle = Constants.isTerzius ? 'Terzius - ':'Curem - ';
        this._titleService.setTitle(websiteTitle + this.course.name);
      }
      setTimeout(setTitle.bind(this), 100);
    });

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });

    if(this.course.media_type == 'video'){
      this.course.media_url = this._cleanUrl(this.course.media_url);
    }

    this.hasCoordinator = this.course.hasOwnProperty('coordinator') && this.course.coordinator.hasOwnProperty('name') ?  true : false;
    this._getTestimonialsRelated(this.course.id);
    this._activatedRoute.params.subscribe(params => {
      this.slug = params.slug;
    })

    this.form = this._formBuilder.group({
      course_id: [this.course.course[0].id, [Validators.required]],
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required]],
      city: [null, [Validators.required]],
      captcha: [null, [Validators.required]]
    });
  }
  
  // ----- PUBLIC METHODS
  toggleDialog(): void {
    this.displayDialog = !this.displayDialog;
  }

  captchaResponse(event): void {
    this.form.patchValue({captcha: event.response});
  }

  captchaExpires(): void {
    this.form.patchValue({captcha: null});
  }

  searchCity(event: any): void {
    let params = {'search_text': event.query};
    this._coreService.getCities(params).subscribe(response => {
      this._cities = response;
      this.citiesNames = response.map(city => city.name);
    })
  }

  defineCity(cityName: any): void {
    this._cities.find(city => {
      if(city.name + ` - `+ city.uf === cityName){
        this._selectedCityId = city.id;
        return true;
      }
    })
  }

  send(): void {
    let params = this.form.getRawValue();
    params['city'] = this._selectedCityId;
    this._coreService.courseQueue(params).subscribe(() => {
      this.displayDialog = false;

      this._snotifyService.warning('Entraremos em contato quando próximas turmas abrirem para este curso.', 'Sucesso!', {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'centerTop'
      });
    })
  }

  // ----- PRIVATE METHODS
  private _getTestimonialsRelated(id: number): void {
    let params = {course_id: id};
    this._coreService.getTestimonials(params).subscribe((response: any) => {
      this.testimonials = response;
    })
  }

  private _getCoursesPackages(): void {
    const params = {
      only_packages: true,
      course_type_ids: [this.course.course_type_id]
    };
    this._coreService.searchCourses(params).subscribe((response: any) => {
      this.packageCourses = response.data;
    })
  }

  private _getNextClasses(): void {
    const params = {
      only_next_classes: true,
      course_type_ids: [this.course.course_type_id],
      city_id: this.course.city_id
    };
    this._coreService.searchCourses(params).subscribe((response: any) => {
      this.nextClasses = response.data;
    })
  }

  private _cleanUrl(url: string) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length >= 11) {
      let url = 'https://www.youtube.com/embed/' + match[2];
      return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return 'error';
    }
  }

}
