//import { Component, OnInit } from '@angular/core';
//import { Course } from 'src/app/interfaces/course';

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';

import { Course } from 'src/app/interfaces/course';
import { CoreService } from 'src/app/core/core.service';
import { Testimonial } from 'src/app/interfaces/testimonial';
import { SeoService } from 'src/app/services/seo.service';
import { Constants } from 'src/app/shared/constants.service';
import { CoursePos } from 'src/app/interfaces/course-pos';

@Component({
  selector: 'cm-postgraduate',
  templateUrl: './postgraduate.component.html',
  styleUrls: ['./postgraduate.component.scss']
})
export class PostgraduateComponent implements OnInit {
  slug: string;
  packageCourses: any = [];
  course: CoursePos;
  infos: any = [];
  index: number;

 

  constructor(
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _domSanitizer: DomSanitizer,
    private _titleService: Title,
    private _seoService: SeoService

  ) {  }

  nextClasses: Course[] = [];
   

  ngOnInit() {
   // console.log(this._activatedRoute.params, "texto");
    this._activatedRoute.params.subscribe(params => {
      
      console.log(params.slug, "slugs");
      this.slug = params.slug;

      console.log(this._activatedRoute, "activity");
      this.course = this._activatedRoute.snapshot.data.courseData;

      
      this.infos  =
    [
      {
        "parentName": "Reconhecido pelo MEC",
        "childProperties":
          [
            { "propertyName": this.course.pos_mec}
          ]
      },
      //{
       // "parentName": "Formato do curso",
       // "childProperties":
       //   [//
       //     { "propertyName": this.course.pos_format },
       //   ]
      //},
      {
        "parentName": "Duração do curso",
        "childProperties":
          [
            { "propertyName": this.course.pos_duration },
          ]
      },
      {
        "parentName": "Público-alvo",
        "childProperties":
          [
            { "propertyName": this.course.target_audience},
          ]
      },
      {
        "parentName": "Monte sua própria turma",
        "childProperties":
          [
            { "propertyName": this.course.pos_condition},
          ]
      }
    ]
    
    
      
      if(this.course.media_type == 'video'){
        this.course.media_url = this._cleanUrl(this.course.media_url);
      }

    
      this._getCoursesPackages();
      this._getNextClasses();

      let setTitle = function(){
        const websiteTitle = Constants.isTerzius ? 'Terzius - ':'Curem - ';
        this._titleService.setTitle(websiteTitle + this.course.name);
      }
      setTimeout(setTitle.bind(this), 100);
    });

    
    

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });
    this._getNextClasses();
    
  }

 

  private _cleanUrl(url: string) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
  
    if (match && match[2].length >= 11) {
      let url = 'https://www.youtube.com/embed/' + match[2];
      return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return 'error';
    }
  }


  toggleAccordian(event, index) {
    var element = event.target;
    element.classList.toggle("active");
    if(this.infos[index].isActive) {
      this.infos[index].isActive = false;
    } else {
      this.infos[index].isActive = true;
    }      
    var panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
 }
  

private _getCoursesPackages(): void {
  const params = {only_packages: true};
  this._coreService.searchCourses(params).subscribe((response: any) => {
    this.packageCourses = response.data;
  
  })
}
private _getNextClasses(): void {
  const params = {
    only_next_classes: true,
    course_type_ids: this.course.course_type_ids,
    search_text: this.course.fullname,
    city_id: this.course.city_id
  };

 // this.nextClasses = this.nextClasses2;
  this._coreService.searchCourses(params).subscribe((response: any) => {
    this.nextClasses = response.data;
  })
}


}

