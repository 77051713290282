import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'cart-item-card',
  templateUrl: './cart-item-card.component.html',
  styleUrls: ['./cart-item-card.component.scss']
})
export class CartItemCard  {
  @Input() courseName: string;
  @Input() courseDate: string;
  @Input() courseValue: number;
  @Input() courseValueDiscount: number;
  @Input() courseId: number;
  @Input() courseLocation: string;
  @Input() courseDiscount: number;
  @Input() courseImage: string;
  @Input() courseSlug: string;
  @Input() courseIsPos: number;
  @Input() courseType: string;
  @Input() courseIsFull: boolean;
  @Input() courseTitle: string;
  @Input() gt: string;
  @Input() configTipoCursoId: number;
  @Input() resultCenterId: number;
  @Input() allowCard: boolean = false;
  @Input() allowBillet: boolean = false;

  constructor(private cartService: CartService) {}

  formatStringWithEllipsis(text: string) {
    if (text.length <= 24) {
      return text;
    } else {
      return text.slice(0, 24) + "...";
    }
  }

  addItemToCart() {
    const data: any = {
      id: this.courseId,
      slug: this.courseSlug,
      image: this.courseImage,
      date: [this.courseDate],
      place: this.courseLocation,
      value: this.courseValueDiscount ? this.courseValueDiscount : this.courseValue,
      isFull: this.courseIsFull,
      isPos: this.courseTitle,
      title: this.courseTitle,
      subtitle: this.courseName,
      gt: this.gt,
      config_tipo_curso_id: this.configTipoCursoId,
      result_center_id: this.resultCenterId,
      allow_card: this.allowCard,
      allow_billet: this.allowBillet
    };
    this.cartService.addRelatedCourseToCart(data);
  }
}
