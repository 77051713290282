export class Unit {
  id: number;
  cidade: string;
  uf: string;
  address: string;
  phones: [];
  email: string;
  schedule: string;
  name: string;
  image_url: string;
  lat: number;
  long: number;
  aditional_info: {
    metro: string;
    onibus: string;
    carro: string;
  };
}
