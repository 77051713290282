import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SeoService } from 'src/app/services/seo.service';
import { Constants } from 'src/app/shared/constants.service';

@Component({
  selector: 'cm-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  fromError: boolean = false;
  isTerzius: boolean;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _seoService: SeoService) { }

  ngOnInit() {
    this._activatedRoute.queryParams.subscribe(params => {
      if(params.hasOwnProperty('erro')){
        this.fromError = true;
      }
    });

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });
    this.isTerzius = Constants.isTerzius;
  }
}
