import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/shared/constants.service';

@Component({
  selector: 'cm-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  banner_url_terzius: any = "https://storage.curem.com.br/arquivos/banner_padrao_terzius.jpg";
  banner_url_curem: any = "https://storage.curem.com.br/arquivos/banner_padrao.jpg";
  titulo: any = "Política de privacidade";
  isTerzius: boolean;


  constructor() { }

  ngOnInit() {
    Constants.isTerzius?this.isTerzius = true:false;
  }

}