import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { SnotifyService } from 'ng-snotify';

import { CoreService } from 'src/app/core/core.service';
import { Constants } from 'src/app/shared/constants.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'cm-work-with-us',
  templateUrl: './work-with-us.component.html',
  styleUrls: ['./work-with-us.component.scss']
})
export class WorkWithUsComponent implements OnInit {
  form: FormGroup;
  states: any;
  banner: string = '';
  private _base64file;

  citiesNames: any;
  private _cities;
  private _selectedCityId;

  constructor(
    private _formBuilder: FormBuilder,
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _seoService: SeoService,
    private _snotifyService: SnotifyService
  ) { }

  ngOnInit() {
    this._getBanner();
    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });

    this.form = this._formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required]],
      city: [null, [Validators.required]],
      uf: [null, [Validators.required]],
      linkedin: [null],
      area: [null, [Validators.required]],
      curriculum: [null, [Validators.required]],
      captcha: [null, [Validators.required]]
    });
    this.states = Constants.states;
  }

  // ----- PUBLIC METHODS
  captchaResponse(event): void {
    this.form.patchValue({captcha: event.response});
  }

  captchaExpires(): void {
    this.form.patchValue({captcha: null});
  }

  searchCity(event: any): void {
    let params = {'search_text': event.query};
    this._coreService.getCities(params).subscribe(response => {
      this._cities = response;
      this.citiesNames = response.map(city => city.name + ` - `+ city.uf);
    })
  }

  defineCity(cityName: any): void {
    this._cities.find(city => {
      if(city.name + ` - `+ city.uf === cityName){
        this._selectedCityId = city.id;
        return true;
      }
    })
  }

  convertFile(file): void {
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this._base64file = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  send(): void {
    const params = this.form.getRawValue();
    params['city'] = this._selectedCityId;
    params['curriculum'] = this._base64file;
    this._coreService.workWithUs(params).subscribe(() => {
      this.form.reset();
      this._selectedCityId = null;
      this._base64file = null;

      this._snotifyService.success('Dados enviados com sucesso.', '', {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'centerTop'
      })
    }, () => {
      this._snotifyService.success('Algum erro ocorreu, tente novamente em instantes', '', {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'centerTop'
      })
    })
  }

  // ----- PRIVATE METHODS
  private _getBanner(): void {
    const params = {page: 'trabalhe-conosco'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }

}
