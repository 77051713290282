import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CoreService } from '../core/core.service';
import { Constants } from 'src/app/shared/constants.service';

@Injectable({providedIn: 'root'})
export class SeoService{

  constructor(
    private _titleService: Title,
    private _metaService: Meta,
    private _coreService: CoreService
  ){}

  public defineTags(url: string): void {
    let params = {page: url};
    this._coreService.getSeoTags(params).subscribe((response: any) => {

      if(Constants.isTerzius) {
        //this._titleService.setTitle("Terzius");
        this._metaService.updateTag({property: 'og:image', content: 'https://terzius.com.br/assets/logo-terzius.png'});
      } else {
        if(response && response.hasOwnProperty('title')){
          this._titleService.setTitle(response.title);
        }
      }

      if(response && response.hasOwnProperty('description')){
        this._metaService.updateTag({name: 'description', content: response.description});
        this._metaService.updateTag({property: 'og:description', content: response.description});
        this._metaService.updateTag({property: 'og:title', content: response.title});
      }

    })
  }
}
