import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CoreService } from 'src/app/core/core.service';

@Component({
  selector: 'cm-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  termsData: any;
  banner: string = '';
  currentName: any = '';
  currentId: any = 0;
  currentContent: any = '';

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _coreService: CoreService) { }

  ngOnInit() {
    this.termsData = this._activatedRoute.snapshot.data.termsData;
    this._activatedRoute.params.subscribe((params: any) => {
      this.currentId = params.id;
      this.currentName = params.name;
      this.termsData.map(item => {
        if(parseInt(item.course_id) == parseInt(this.currentId)){
          this.currentContent = item.content;
        }
      })
    })
    this._getBanner();
  }

  // ----- PRIVATE METHODS
  private _getBanner(): void {
    const params = {page: 'sobre-nos'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }
}
