import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { CoreService } from 'src/app/core/core.service';
import { Instructor } from 'src/app/interfaces/instructor';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'cm-instructors',
  templateUrl: './instructors.component.html',
  styleUrls: ['./instructors.component.scss']
})
export class InstructorsComponent implements OnInit {
  private _allInstructors: Instructor[] = [];
  instructorList: Instructor[] = [];
  form: FormGroup;
  totalRecords: number = 0;
  perPageRecords: number = 12;
  banner: string;
  courseNames: any;
  isSearching: boolean = false;

  constructor(
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _seoService: SeoService,
    private _formBuilder: FormBuilder
    ) { }

  ngOnInit() {
    this._getInstructors();
    this._getBanner();

    this.form = this._formBuilder.group({
      name: [],
      course: []
    })

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });
  }

  // ----- PUBLIC METHODS
  paginate(event: any): void {
    const first = event.page * this.perPageRecords;
    const last = (event.page * this.perPageRecords) + this.perPageRecords;
    this.instructorList = this._allInstructors.slice(first, last);
  }

  filterInstructors(): void {
    this.isSearching = true;
    const params = this.form.getRawValue();

    this.form.get('name').value ? '' : delete params['name'];
    this.form.get('course').value ? params['course'] = params.course.id : delete params['course'];


    this._coreService.getInstructors(params)
    .pipe(finalize(() => this.isSearching = false))
    .subscribe((response: any) => {
      this.instructorList = response.slice(0, 12);
      this._allInstructors = response;
      this.totalRecords = response.length;
    })
  }

  listCoursesSuggestions(event: any): void {
    let params = {'search_text': event.query};
    this._coreService.getCourseTypes(params).subscribe((response: any) => {
      this.courseNames = response.map(course => {
        return {
          alias: (course.name.length > 37) ? (course.name.slice(0, 37) + '...') : course.name,
          name: course.name,
          id: course.id
        }
      });
    })
  }

  // ----- PRIVATE METHODS
  private _getInstructors(): void {
    this._coreService.getInstructors().subscribe((response: any) => {
      this.instructorList = response.slice(0, 12);
      this._allInstructors = response;
      this.totalRecords = response.length;
    })
  }

  private _getBanner(): void {
    const params = {page: 'instrutores'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }
}
