import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'cm-complementary-course-card',
  templateUrl: './complementary-course-card.component.html',
  styleUrls: ['./complementary-course-card.component.scss']
})
export class ComplementaryCourseCardComponent implements OnChanges {
  @Input() id: number;
  @Input() slug: string;
  @Input() image: string;
  @Input() name: string;
  @Input() fullname: string;
  @Input() dates: string[];
  @Input() place: string;
  @Input() targetAudience: string;
  @Input() tag: any;
  @Input() isPromotion: boolean = false;
  @Input() remainTime: number;
  @Input() remainText: string;
  @Input() value: string;
  @Input() warning: string;

  @Input() disallowBilletReason: string;
  @Input() disallowCardReason: string;
  @Input() allowBillet: boolean;
  @Input() allowCard: boolean;
  @Input() allowPac: boolean;
  @Input() allowSedex: boolean;
  @Input() allowRetrieve: boolean;

  @Output() addCourse = new EventEmitter();
  @Output() removeCourse = new EventEmitter();

  @Input() isRemoved = false;
  isAdded: boolean = false;

  ngOnChanges(){
    if(this.isRemoved == true){
      this._cleanRemoved();
    }
  }

  // ----- PUBLIC METHODS
  addComplementaryCourse(): void {
    this.isAdded = true;

    let data = {
      id: this.id,
      name: this.name,
      fullname: this.fullname,
      place: this.place,
      dates: this.dates,
      value: this.value,
      allow_billet: this.allowBillet,
      allow_card: this.allowCard,
      allow_pac: this.allowPac,
      allow_sedex: this.allowSedex,
      allow_retrieve: this.allowRetrieve,
      disallow_billet_reason: this.disallowBilletReason,
      disallow_card_reason: this.disallowCardReason,
      warning: this.warning,
      shipping_value: 0,
      fee: 0,
      discount: 0
    }

    this.addCourse.emit(data);
  }

  removeComplementaryCourse(): void {
    this.isAdded = false;
    this.removeCourse.emit(this.id);
  }

  // ----- PRIVATE METHODS
  private _cleanRemoved(): void {
    this.isAdded = false;
    this.isRemoved = false;
  }
}
