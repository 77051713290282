import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'cm-courses-package',
  templateUrl: './courses-package.component.html',
  styleUrls: ['./courses-package.component.scss']
})
export class CoursesPackageComponent {
  @Input() id: number;
  @Input() slug: string;
  @Input() image: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() date: string;
  @Input() place: string;
  @Input() tag: any;
  @Input() remainTime: number;
  @Input() remainText: string;
  @Input() desconto: number;

  constructor (
    private _router: Router
  ) { }

  addComboToCart(slug){    
    this._router.navigate(['/carrinho'], { queryParams: { combo: slug } });
  }
  
}
