import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { CoreService } from 'src/app/core/core.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'cm-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  form: FormGroup;
  faqList: any[];
  banner: string = '';
  isSearching: boolean = false;

  constructor(
    private _coreService: CoreService,
    private _activatedRoute: ActivatedRoute,
    private _seoService: SeoService,
    private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this._getBanner();
    this._listFaq();

    this.form = this._formBuilder.group({
      search_text: []
    })

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });
  }

  // ----- PUBLIC METHODS
  findFaq(): void {
    this.isSearching = true;
    let params = this.form.getRawValue();
    this._coreService.getFaq(params)
    .pipe(finalize(() => this.isSearching = false))
    .subscribe((response: any) => {
      this.faqList = response;
    })
  }

  // ----- PRIVATE METHODS
  private _listFaq(): void {
    this._coreService.getFaq().subscribe((response: any) => {
      this.faqList = response;
    })
  }

  private _getBanner(): void {
    const params = {page: 'faq'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }
}
