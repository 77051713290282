import { Component, Input } from '@angular/core';

@Component({
  selector: 'cm-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss']
})
export class SectionTitleComponent {
  @Input() title: string;
  @Input() showBlogLink: boolean = false;
  @Input() showNextClassesLink: boolean = false;
  @Input() showPromoCourseLink: boolean = false;
  @Input() showPackageCourseLink: boolean = false;
  @Input() showFinanciableCourseLink: boolean = false;

}
