import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData, CommonModule } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
//import { WheelComponent } from "./pages/black-friday/wheel/wheel.component"

import { CoreModule } from "./core/core.module";
import { PagesModule } from "./pages/pages.module";
import { WhatsappButtomComponent } from './shared/whatsapp-buttom/whatsapp-buttom.component';

import { NgxHotjarModule } from 'ngx-hotjar';

import { NgxWheelModule } from 'ngx-wheel';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { ReactiveFormsModule } from "@angular/forms";


registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    WhatsappButtomComponent,
    //RouteGuardService,
    //WheelComponent,
  ],
  imports: [
    NgxWheelModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgxHotjarModule.forRoot('1177925'),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    PagesModule,
    SnotifyModule,
    CommonModule,
    TransferHttpCacheModule,
    NgtUniversalModule,
    ReactiveFormsModule
   // RouteGuardService

  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt'},
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule {}
