import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { SnotifyService } from 'ng-snotify';

import { CoreService } from 'src/app/core/core.service';
import { Constants } from 'src/app/shared/constants.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'cm-talk-to-us',
  templateUrl: './talk-to-us.component.html',
  styleUrls: ['./talk-to-us.component.scss']
})
export class TalkToUsComponent implements OnInit {
  form: FormGroup;
  states: any;
  jobs: any;
  banner: string = '';
  subjects: any = [
   
    {value: '5', label: 'Quero comprar um curso'},
    {value: '6', label: 'Quero montar uma turma'},
    {value: '7', label: 'Emissão de certificado'},
    {value: '8', label: 'Livro e Material'},
    {value: '9', label: 'Dúvidas sobre o meu curso'},
    {value: '10', label: 'Pagamento'},
    {value: '1', label: 'Sugestão'},
    {value: '2', label: 'Reclamação'},
    {value: '3', label: 'Elogio'},
    {value: '4', label: 'Dúvida'}
  ];

  citiesNames: any;
  private _cities;
  private _selectedCityId;

  constructor(
    private _formBuilder: FormBuilder,
    private _coreService: CoreService,
    private _snotifyService: SnotifyService,
    private _activatedRoute: ActivatedRoute,
    private _seoService: SeoService
  ) { }

  ngOnInit() {
    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });

    this._getBanner();
    this.form = this._formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required]],
      city: [null, [Validators.required]],
      uf: [null, [Validators.required]],
      subject: [null, [Validators.required]],
      jobs: [null, [Validators.required]],
      message: [null, [Validators.required]],
      captcha: [null, [Validators.required]]
    });

    this.states = Constants.states;
    this.jobs = Constants.jobs;
  }
  // ----- PUBLIC METHODS
  captchaResponse(event): void {
    this.form.patchValue({captcha: event.response});
  }

  captchaExpires(): void {
    this.form.patchValue({captcha: null});
  }

  searchCity(event: any): void {
    let params = {'search_text': event.query};
    this._coreService.getCities(params).subscribe(response => {
      this._cities = response;
      this.citiesNames = response.map(city => city.name + ` - `+ city.uf);
    })
  }

  defineCity(cityName: any): void {
    this._cities.find(city => {
      if(city.name + ` - `+ city.uf === cityName){
        this._selectedCityId = city.id;
        return true;
      }
    })
  }

  send(): void {
    let params = this.form.getRawValue();
    params['city'] = this._selectedCityId;
    this._coreService.talkToUs(params).subscribe(() => {
      this.form.reset();
      this._selectedCityId = null;

      this._snotifyService.success('Dados enviados com sucesso.', '', {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'centerTop'
      })
    }, () => {
      this._snotifyService.success('Algum erro ocorreu, tente novamente em instantes', '', {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'centerTop'
      })
    })
  }

  // ----- PRIVATE METHODS
  private _getBanner(): void {
    const params = {page: 'fale-conosco'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }
}
