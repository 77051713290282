import { Component, Input } from '@angular/core';

@Component({
  selector: 'cm-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent{
  @Input() slug: string;
  @Input() imageUrl: string;
  @Input() date: string;
  @Input() title: string;
  @Input() author: string;
  @Input() tags: any[];

}
