import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cm-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss']
})
export class TestimonialComponent{
  @Input() author: string;
  @Input() testimonial: string;
  @Input() mediaType: any;
  @Input() media: any;

  OnInit(){
    if(this.mediaType == 'video'){
      this.media = this._cleanUrl(this.media);
    }
  }

  constructor(private _domSanitizer: DomSanitizer) { }

  // ----- PRIVATE METHODS
  private _cleanUrl(url: string) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length >= 11) {
      let url = 'https://www.youtube.com/embed/' + match[2];
      return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return 'error';
    }
  }
}
