import {  Injectable } from '@angular/core';
import { SnotifyService } from 'ng-snotify';

@Injectable({
  providedIn: 'root'
})
export class CartCheckoutService {
  constructor (
    private _snotifyService: SnotifyService,
  ) { }

  showErrorMessagePopup() {
    this._snotifyService.error("Falha ao tentar realizar a compra.", "", {
      timeout: 4500,
      showProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      position: "centerTop",
    });
  }
}
