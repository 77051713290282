import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// third party
import { CarouselModule } from "primeng/carousel";
import { ButtonModule } from "primeng/button";
import { SelectButtonModule } from "primeng/selectbutton";
import { InputMaskModule } from "primeng/inputmask";
import { DropdownModule } from "primeng/dropdown";
import { AccordionModule } from "primeng/accordion";
import { AgmCoreModule } from "@agm/core";
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ShareModule } from '@ngx-share/core';
import { CaptchaModule } from 'primeng/captcha';
import { DialogModule } from 'primeng/dialog';
import { SnotifyModule } from 'ng-snotify';
// first party
import { HomeComponent } from "./home/home.component";
import { SharedModule } from "../shared/shared.module";
import { CoreModule } from "../core/core.module";
import { CheckoutComponent } from "./checkout/checkout.component";
import { CoursesComponent } from "./courses/courses.component";
import { CoursesBlackComponent } from "./coursesBlack/coursesBlack.component";
import { ClassDetailsComponent } from "./class-details/class-details.component";
import { AssembleClassComponent } from "./assemble-class/assemble-class.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { BlogComponent } from "./blog/blog.component";
import { BlogPostComponent } from "./blog-post/blog-post.component";
import { UnitsComponent } from "./units/units.component";
import { FaqComponent } from "./faq/faq.component";
import { TalkToUsComponent } from "./talk-to-us/talk-to-us.component";
import { WorkWithUsComponent } from "./work-with-us/work-with-us.component";
import { InstructorsComponent } from "./instructors/instructors.component";
import { LoginComponent } from "./login/login.component";
import { HttpClientModule } from '@angular/common/http';
import { CourseComponent } from './course/course.component';
import { StopClickPropagationModule } from '../directives/stop-click-propagation/stop-click-propagation.module';
import { WhoWeEmpowerComponent } from './who-we-empower/who-we-empower.component';
import { TermsComponent } from './terms/terms.component';
import { SafeHtmlModule } from '../core/pipes/safe-html/safe-html.module';
import { ErrorComponent } from './error/error.component';
import { WheelComponent } from './black-friday/wheel/wheel.component';

import { NgxWheelModule } from 'ngx-wheel';
import { PostgraduateComponent } from './postgraduate/postgraduate.component';
import { PosDetailsComponent } from './pos-details/pos-details.component';
import { PoliticaCookiesComponent } from './politica-cookies/politica-cookies.component';
import { SimsaveComponent } from './simsave/simsave.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CartComponent } from "./cart/cart.component";
import { CartCheckoutComponent } from './cart-checkout/cart-checkout.component';

@NgModule({
  declarations: [
    HomeComponent,
    CheckoutComponent,
    CoursesComponent,
    CoursesBlackComponent,
    ClassDetailsComponent,
    AssembleClassComponent,
    AboutUsComponent,
    BlogComponent,
    BlogPostComponent,
    UnitsComponent,
    FaqComponent,
    TalkToUsComponent,
    WorkWithUsComponent,
    InstructorsComponent,
    LoginComponent,
    CourseComponent,
    WhoWeEmpowerComponent,
    TermsComponent,
    ErrorComponent,
    WheelComponent,
    PostgraduateComponent,
    PosDetailsComponent,
    PoliticaCookiesComponent,
    SimsaveComponent,
    PrivacyPolicyComponent,
    CartComponent,
    CartCheckoutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    CarouselModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ShareModule,
    StopClickPropagationModule,
    SafeHtmlModule,
    NgxWheelModule,
    SnotifyModule,
    DialogModule,
    AutoCompleteModule,
    CalendarModule,
    ButtonModule,
    SelectButtonModule,
    InputMaskModule,
    DropdownModule,
    AccordionModule,
    PaginatorModule,
    CaptchaModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyC37c-g2AXnDGNCEALL5G2uyLAQxxhRF1Q"
    })
  ],
  exports: [],
  providers: [CurrencyPipe]
})
export class PagesModule {}
