import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Constants } from '../constants.service';
import { CoreService } from 'src/app/core/core.service';
import { DateFormat } from 'src/app/services/date-format.service';

@Component({
  selector: 'cm-search-course-box',
  templateUrl: './search-course-box.component.html',
  styleUrls: ['./search-course-box.component.scss']
})
export class SearchCourseBoxComponent implements OnInit {
  @Input() showTitle: boolean = false;
  @Input() showFooter: boolean = false;

  form: FormGroup;
  formDialog: FormGroup;
  displayDialog: boolean = false;
  calendarPt: any;

  citiesNames: any;
  private _cities;
  private _selectedCityId;

  courseNamesMirror: any;

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _coreService: CoreService
    ) { }

  ngOnInit() {
    this.calendarPt = Constants.calendarPtBr;
    this.form = this._formBuilder.group({
      'search_text': [null],
      'city_id': [null],
      'date': [null]
    })

    this.formDialog = this._formBuilder.group({
      name: [null, [Validators.required]],
      email: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      captcha: [null, [Validators.required]]
    })
  }

  // ----- PUBLIC METHODS
  searchCourses(): void {
    let values = this.form.getRawValue();
    this._selectedCityId ? values['city_id'] = [this._selectedCityId] : delete values['city_id'];
    this.form.get('search_text').value ? values['search_text'] = values.search_text.name : delete values['search_text'];
    this.form.get('date').value ? values['date'] = DateFormat.format(values['date']) : delete values['date'];
    this._router.navigate(['/cursos'], { queryParams: values });
  }

  toggleDialog(): void {
    this.displayDialog = !this.displayDialog;
  }

  sendContactData(): void {
    let values = this.formDialog.getRawValue();
    this._coreService.talkToUs(values).subscribe(() => {
      this.displayDialog = false;
      this.formDialog.reset();
    })
  }

  searchCity(event: any): void {
    let params = {'search_text': event.query};
    this._coreService.getCities(params).subscribe(response => {
      this._cities = response;
      this.citiesNames = response.map(city => city.name + ` - `+ city.uf);
    })
  }

  defineCity(cityName: any): void {
    this._cities.find(city => {
      if(city.name + ` - `+ city.uf === cityName){
        this._selectedCityId = city.id;
        return true;
      }
    })
  }

  filterCourseSugsestion(event: any): void {
    let params = {'search_text': event.query};
    this._coreService.getCourseTypes(params).subscribe((response: any) => {
      this.courseNamesMirror = response.map(course => {
        return {
          alias: (course.name.length > 25) ? (course.name.slice(0, 25) + '...') : course.name,
          name: course.name,
          id: course.id
        }
      });
    })
  }

  captchaResponse(event): void {
    this.formDialog.patchValue({captcha: event.response});
  }

  captchaExpires(): void {
    this.formDialog.patchValue({captcha: null});
  }
}
