import { EventEmitter, Injectable } from '@angular/core';
import { Cart, Item, Address, Combo } from '../interfaces/cart';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../shared/constants.service';
import { ItemShipping } from '../interfaces/item-shipping';
import { retry } from 'rxjs/operators';
import { SnotifyService } from 'ng-snotify';
import { Router } from '@angular/router';
import { RelatedCourse, RelatedCourseCart } from '../interfaces/related-course';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cartKey = 'cart';
  private apiBaseUrl = Constants.apiBaseUrl;

  public cartUpdated: EventEmitter<void> = new EventEmitter<void>();

  constructor (
    private http: HttpClient,
    private _snotifyService: SnotifyService,
    private _router: Router
  ) { }

  public addItemToCart(item: Item) {

    let cart: Cart | Partial<Cart> = JSON.parse(sessionStorage.getItem(this.cartKey));

    if (!cart) {
      cart = this.setInitialCartValue();
    }

    const differentPaymentType = cart.items.some((itemCart) =>
      itemCart.allow_card !== item.allow_card && itemCart.allow_billet !== item.allow_billet
    );
    if (differentPaymentType) {
      this._snotifyService.error("Produtos com formas de pagamentos não compatíveis.", "", {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: "centerTop",
      });
      return;
    }

    //verifica se possuem gateways diferentes ou se é maxipago e centro de resultados diferentes (maxipago exige que seja mesmo centro de resultado)
    const gtDifferent = cart.items.some((itemCart) => (itemCart.gt !== item.gt) || (item.gt == 1 && itemCart.result_center_id !== item.result_center_id));
    if (gtDifferent) {
      this._snotifyService.error("Produtos de regiões não compatíveis.", "", {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: "centerTop",
      });
      return;
    }

    const itemExists = cart.items.some((itemInCart) => itemInCart.id === item.id);
    const courseExists = cart.items.some((itemCart) => itemCart.config_tipo_curso_id === item.config_tipo_curso_id);
    if (itemExists || courseExists) {

      this._snotifyService.error("Este item já existe no carrinho.", "", {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: "centerTop",
      });

      return;
    }

    cart.items.push(item);

    if(item.allow_billet === false) {
      cart.paymentOptions.allow_billet = false;
    }

    if(item.allow_card === false) {
      cart.paymentOptions.allow_card = false;
    }

    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
    this.cartUpdated.emit();
    this._router.navigate(['/carrinho']);
  }

  public addRelatedCourseToCart(item: any): void {
    let cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));

    if (!cart) {
      cart.items = [];
    }

    const itemExists = cart.items.some((itemInCart) => itemInCart.id === item.id);
    // const courseExists = cart.some((itemCart) => itemCart.config_tipo_curso_id === item.config_tipo_curso_id);
    // const resultCenterExists = cart.some((itemCart) => itemCart.result_center_id === item.result_center_id);

    if (itemExists) {

      this._snotifyService.error("Este item já existe no carrinho.", "", {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: "centerTop",
      });

      return;
    }

    cart.items.push(item);
    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
    this.cartUpdated.emit();
  }

  public getTotalItensInCart(): number {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    if(!cart){
      return 0;
    }
    return cart.items.length;
  }

  public getCart() {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    return cart;
  }

  public getPaymentOptionsFromCart() {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    return cart.paymentOptions;
  }

  public getAddressFromCart() {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    return cart.address;
  }

  public getItemsFromCart() {
    const cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    return cart.items;
  }

  public getItemsShippingValue(data: ItemShipping) {
    return this.http.post(this.apiBaseUrl + '/calcular-frete', data)
    .pipe(retry(1));
  }

  public updateAddressToCart(address: Address) {
    let cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    cart.address = address;
    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
  }

  public updateItemsToCart(items: Item[]) {
    let cart: Cart = JSON.parse(sessionStorage.getItem(this.cartKey));
    cart.items = items;
    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
  }

  public deleteItemsFromCart(id: number) {
    let cart: Cart = this.getCart();

    cart.items = cart.items.filter((item) => item.id !== id);

    for(let i in cart.combo) {

      let qtd = 0;
      for(let j in cart.items) {
        if(cart.combo[i].token == cart.items[j].token){
          qtd++;
        }
      }

      if(cart.combo[i].qtd != qtd){
        cart.combo.splice(parseInt(i), 1)
      }
    }

    sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
    this.cartUpdated.emit();

    return cart.items;
  }

  public deleteAllItemsFromCart() {
    sessionStorage.removeItem('cart');
    this.setInitialCartValue();
    this.cartUpdated.emit();
  }

  public setInitialCartValue() {
    const cart = sessionStorage.getItem('cart');
    if (!cart) {
      sessionStorage.setItem(this.cartKey, '{"combo":[],"paymentOptions":{"allow_card":true,"allow_billet":true},"address":{"status":false,"shippingDestiny":""},"items":[]}');
    }
    return JSON.parse(sessionStorage.getItem(this.cartKey));
  }

  public setComboCartValue(combo: Combo) {
    let cart: Cart | Partial<Cart> = JSON.parse(sessionStorage.getItem(this.cartKey));
    
    let test = false;
    for(let i in cart.combo) {
      if(cart.combo[i].token == combo.token){
        test = true;
      }
    }

    if(!test) {
      cart.combo.push(combo);
      sessionStorage.setItem(this.cartKey, JSON.stringify(cart));
    }
  }

  public getItensCombo(token: string) {

    return this.http.get(`${this.apiBaseUrl}/combo/${token}`).pipe(retry(1));
  }
}
