import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { SnotifyService } from 'ng-snotify';

import { CoreService } from 'src/app/core/core.service';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from 'src/app/services/seo.service';
import { WINDOW } from '@ng-toolkit/universal';
import { Constants } from 'src/app/shared/constants.service';

@Component({
  selector: 'cm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  banner: string = '';
  form: FormGroup;
  recoveryForm: FormGroup;
  displayDialog: boolean = false;
  terzius: boolean = false;
  redirectAccepted:boolean = false;

  constructor(@Inject(WINDOW) private window: Window,
    private _formBuilder: FormBuilder,
    private _snotifyService: SnotifyService,
    private _activatedRoute: ActivatedRoute,
    private _seoService: SeoService,
    private _coreService: CoreService) { }

  ngOnInit() {
    this._getBanner();
    this.form = this._formBuilder.group({
      user: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });

    this.recoveryForm = this._formBuilder.group({
      cpf: [null, [Validators.required]]
    });

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });

    if(Constants.isTerzius) {
      this.terzius = true;
    }
  }

  // ----- PUBLIC METHODS


  login(): void {
    const params = this.form.getRawValue();
    this._coreService.login(params).subscribe((response: any) => {
      let options = {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'centerTop'
      }
      console.log(Constants.isTerzius)
      if(response.hasOwnProperty('redirect_url')){
        this.window.open(response.redirect_url, "_self");
        this.form.reset();
      }else{
        this._snotifyService.warning(response.status, '', options);
      }
    })
  }

  toggleDialog(): void {
    this.displayDialog = !this.displayDialog;
  }

  recoveryPassword(): void {
    const params = this.recoveryForm.getRawValue();
    this._coreService.recoveryPassword(params).subscribe((response: any) => {
      let options = {
        timeout: 3000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'centerTop'
      }

      if(response.status === 'erro'){
        this._snotifyService.error(response.message, 'Erro!', options);
      }else{
        this._snotifyService.success(response.message, 'Sucesso!', options);
      }
      this.recoveryForm.reset();
      this.displayDialog = false;
    })
  }

  // ----- PRIVATE METHODS
  private _getBanner(): void {
    const params = {page: 'area-do-aluno'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }

  acceptRedirect(value: boolean): void {
    this.redirectAccepted = value;
  }

  clickRedirect(event){
    if (!this.redirectAccepted) {
      event.preventDefault();
    }
  }
}
