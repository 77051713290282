import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Constants } from '../constants.service';
import { CoreService } from 'src/app/core/core.service';
import { DateFormat } from 'src/app/services/date-format.service';

@Component({
  selector: 'cm-course-filter',
  templateUrl: './course-filter.component.html',
  styleUrls: ['./course-filter.component.scss']
})
export class CourseFilterComponent implements OnInit {
  @Output() sendParameters = new EventEmitter();
  @Input() searchParams: any;
  calendarPt: any;
  form: FormGroup;
  isSearching: boolean = false;

  cityTags: any[] = [];
  areas: any = [];
  courseGroups: any = [];
  citiesNames: any;
  courseNames: any;

  private _selectedGroups = [];

  constructor(
    private _coreService: CoreService,
    private _formBuilder: FormBuilder,
    ) { }

  ngOnInit() {
    this.calendarPt = Constants.calendarPtBr;
    this.form = this._formBuilder.group({
      'search_text': [],
      'course_area_id': [],
      'course_type_ids': [],
      'city_id': [],
      'date': [],
      'only_packages': [],
      'only_promotion': [],
      'only_next_classes': [],
      'only_financiable': []
    })

    this._getCourseAreas();
    this._getCourseGroups();
  }

  ngAfterViewInit(){
    if(this.searchParams.hasOwnProperty('search_text')){
      let temp = function(){
        let courseText = this.searchParams['search_text'];
        this.courseNames = [{
          alias: (courseText.length > 25) ? (courseText.slice(0, 25) + '...') : courseText,
          name: courseText,
          id: 0
        }]
        this.form.patchValue({search_text: this.courseNames[0]})
      }

      setTimeout(temp.bind(this))
    }

    if(this.searchParams.hasOwnProperty('date')){
      let temp = function(){
        this.form.patchValue({date: new Date(this.searchParams['date'])})
      }

      setTimeout(temp.bind(this))
    }

    if(this.searchParams.hasOwnProperty('only_packages')){
      this.form.patchValue({only_packages: this.searchParams['only_packages']})
    }

    if(this.searchParams.hasOwnProperty('only_promotion')){
      this.form.patchValue({only_promotion: this.searchParams['only_promotion']})
    }

    if(this.searchParams.hasOwnProperty('only_financiable')){
      this.form.patchValue({only_financiable: this.searchParams['only_financiable']})
    }

    if(this.searchParams.hasOwnProperty('only_next_classes')){
      this.form.patchValue({only_next_classes: this.searchParams['only_next_classes']})
    }
  }

  // ----- PUBLIC METHODS
  addCityTag(event: string): void {
    this.cityTags.push(event);
    this.form.patchValue({'city_id': ''});
  }

  removeCityTag(tag: string): void {
    this.cityTags = this.cityTags.filter(cityTag => cityTag !== tag);
  }

  clearCityFilter(): void {
    this.cityTags.length = 0;
    this.form.patchValue({'city_id': null});
  }

  clearCourseFilter(): void {
    this.form.patchValue({search_text: null});
  }

  clearAreaFilter(): void {
    this.form.patchValue({course_area_id: null});
  }

  clearDateFilter(): void {
    this.form.patchValue({date: null});
  }

  listCoursesSuggestions(event: any): void {
    let params = {'search_text': event.query};
    this._coreService.getCourseTypes(params).subscribe((response: any) => {
      this.courseNames = response.map(course => {
        return {
          alias: (course.name.length > 25) ? (course.name.slice(0, 25) + '...') : course.name,
          name: course.name,
          id: course.id
        }
      });
    })
  }

  searchCity(event: any): void {
    let params = {'search_text': event.query};
    this._coreService.getCities(params).subscribe(response => {
      this.citiesNames = response.map(city => {
        return {
          name: city.name + ` - `+ city.uf,
          id: city.id
        }
      });
    })
  }
  
  selectCourseGroups(status: boolean, types): void {
    let typeIds = types.map(type => type.id)

    if(status){
      typeIds.map(id => this._selectedGroups.push(id))
    }else{
      typeIds.map(id => {
        this._selectedGroups = this._selectedGroups.filter(group => group != id);
      })
    }
  }

  filter(): void {
    this.isSearching = true;
    let data = this.form.getRawValue();

    this.form.get('search_text').value ? data['search_text'] = data.search_text.name : delete data['search_text'];
    this.form.get('course_area_id').value ? data['course_area_id'] = data.course_area_id.id : delete data['course_area_id'];
    this.cityTags.length > 0 ? data['city_id'] = this.cityTags.map(city => city.id) : delete data['city_id'];
    this.form.get('date').value ? data['date'] = DateFormat.format(data.date) : delete data['date'];
    this.form.get('only_promotion').value ? '' : delete data['only_promotion'];
    this.form.get('only_packages').value ? '' : delete data['only_packages'];
    this.form.get('only_next_classes').value ? '' : delete data['only_next_classes'];
    this._selectedGroups.length > 0 ? data['course_type_ids'] = [...this._selectedGroups] : delete data['course_type_ids'];
    this.form.get('only_financiable').value ? '' : delete data['only_financiable'];
    this.sendParameters.emit(data);

    let returnState = function(){
      this.isSearching = false;
    }

    setTimeout(returnState.bind(this), 1000);
  }

  // ----- PRIVATE METHODS
  private _getCourseAreas(): void {
    this._coreService.getCourseAreas().subscribe((response: any) => {
      this.areas = response;

      if(this.searchParams.hasOwnProperty('course_area_id')){
        let area = this.areas.filter(item => {
          if(item.id == this.searchParams['course_area_id']){
            return true;
          }
        })

        this.form.patchValue({course_area_id: area[0]})
      }
    })
  }

  private _getCourseGroups(): void {
    this._coreService.getCourseGroups().subscribe((response: any) => {
      this.courseGroups = response;
    })
  }
}
