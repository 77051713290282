import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/core/core.service';
import { Constants } from 'src/app/shared/constants.service';

@Component({
  selector: 'cm-whatsapp-buttom',
  templateUrl: './whatsapp-buttom.component.html',
  styleUrls: ['./whatsapp-buttom.component.scss']
})
export class WhatsappButtomComponent implements OnInit {
  phone = [];
  isTerzius: boolean;

  constructor(private _coreService: CoreService) { }

  ngOnInit() {
    this._getPhoneList();
    this.isTerzius = Constants.isTerzius;
  }

  cleanPhoneNumber(phone: any): string {
    return phone.replace(/\D/g,'');
  }

  // ----- PRIVATE METHODS
  private _getPhoneList(): void {
    this._coreService.getPhones().subscribe((response: any) => {
      this.phone = response.whatsapp;
    })
  }
}
