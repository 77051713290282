import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CoreService } from 'src/app/core/core.service';
import { Course } from 'src/app/interfaces/course';
import { SeoService } from 'src/app/services/seo.service';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'cm-courses-black',
  templateUrl: './coursesBlack.component.html',
  styleUrls: ['./coursesBlack.component.scss']
})
export class CoursesBlackComponent implements OnInit {
  searchParams: any = {};
  mobileFilters: boolean = false;
  courses: Course[] = [];
  banner: string = '';

  totalRecords: number = 0;
  perPageRecords: number = 10;

  constructor(@Inject(WINDOW) private window: Window, 
    private _coreService: CoreService,
    private _seoService: SeoService,
    private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this._getBanner();
    this._activatedRoute.queryParams.subscribe(params => {
      let newParams = {...params}
      if(newParams.hasOwnProperty('course_type_ids')){
        newParams['course_type_ids'] = [parseInt(newParams['course_type_ids'])]
      }

      if(newParams.hasOwnProperty('city_id')){
        newParams['city_id'] = [parseInt(newParams['city_id'])]
      }

      this.searchParams = newParams;
      //params['page'] = event.page + 1 + '';
    //params['per_page'] = this.perPageRecords.toString();
    this.searchParams['black_friday'] = 'true';
      this._findCourses(this.searchParams);
    });

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });
  }

  // ----- PUBLIC METHODS
  toggleMobileFilters(): void {
    this.mobileFilters = !this.mobileFilters;
  }

  paginate(event) {
    let params = {};
    params['page'] = event.page + 1 + '';
    params['per_page'] = this.perPageRecords.toString();
    params['black_friday'] = 'true';

    this._findCourses(params);
  }

  getParameters(e){
    this._coreService.searchCourses(e).subscribe((response: any) => {
      this.mobileFilters = false;
      this.totalRecords = response.total;
      this.courses = response.data;
      this.window.scrollTo(0,200);
    })
  }

  // ----- PRIVATE METHODS
  private _findCourses(data = {}): void {
    let params = {...this.searchParams, ...data};
    this._coreService.searchCourses(params).subscribe((response: any) => {
      this.totalRecords = response.total;
      this.courses = response.data;
    })
  }

  private _getBanner(): void {
    const params = {page: 'cursos'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }
}
