import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { CoreService } from 'src/app/core/core.service';
import { SeoService } from 'src/app/services/seo.service';

@Component({
  selector: 'cm-who-we-empower',
  templateUrl: './who-we-empower.component.html',
  styleUrls: ['./who-we-empower.component.scss']
})
export class WhoWeEmpowerComponent implements OnInit {
  banner: string = '';
  clients: any;

  videoUrl: any;
  displayDialog: boolean = false;

  constructor(
    private _coreService: CoreService,
    private _domSanitizer: DomSanitizer,
    private _seoService: SeoService,
    private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this._getBanner();
    this.clients = this._activatedRoute.snapshot.data.clientsData;

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });
  }

  //----- PUBLIC METHODS
  closeVideo(): void {
    this.displayDialog = false;
  }

  openVideo(event): void {
    this.videoUrl = this._cleanUrl(event);
    this.displayDialog = true;
  }

  // ----- PRIVATE METHODS
  private _getBanner(): void {
    const params = {page: 'quem-capacitamos'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }

  private _cleanUrl(url: string) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length >= 11) {
      let url = 'https://www.youtube.com/embed/' + match[2];
      return this._domSanitizer.bypassSecurityTrustResourceUrl(url);
    } else {
      return 'error';
    }
  }
}
