import { environment } from "../../environments/environment";
import { WINDOW } from "@ng-toolkit/universal";
import { Inject } from "@angular/core";

export class Constants {
  public static isTerzius =
    window.location.hostname == "terzius.com.br" ||
    window.location.hostname == "localhost"
      ? true
      : false;

  public static get apiBaseUrl(): any {
    let apiUrl = environment.apiUrl;
    this.isTerzius = false;
    //return 'http://127.0.0.1:8000/api/site';
    if (this.isTerzius) {
      apiUrl = apiUrl.replace("site", "terzius");
    }
    //if (this.isTerzius) { apiUrl = apiUrl.replace("curem", "terzius"); }
    return apiUrl;
    //console.log(apiUrl);
    //return 'https://apibeta.curem.com.br/public/api/terzius'
    //return apiUrl;
    // if(window.location.hostname == 'curem.com.br'){

    //   return 'https://api.curem.com.br/public/api/site'

    // }else if(window.location.hostname == 'terzius.curem.com.br'){
    //   //console.log(window.location.hostname, "Host Terzius");
    //   return 'https://apibeta.curem.com.br/public/api/terzius'

    // }else if(window.location.hostname == 'curembeta.curem.com.br'){

    //   return 'https://apibeta.curem.com.br/public/api/site'

    // }else{

    //   return 'http://127.0.0.1:8000/api/site'

    // }
  }

  public static get googleMapsApiKy(): string {
    return "AIzaSyC37c-g2AXnDGNCEALL5G2uyLAQxxhRF1Q";
  }

  public static get calendarPtBr(): any {
    return {
      firstDayOfWeek: 0,
      dayNames: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      dayNamesMin: ["Do", "Se", "Te", "Qa", "Qi", "Se", "Sa"],
      monthNames: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      monthNamesShort: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      today: "Hoje",
      clear: "Limpar",
      dateFormat: "dd/mm/yy",
      weekHeader: "Se",
    };
  }

  public static get states(): any {
    return [
      { value: "AC", label: "Acre" },
      { value: "AL", label: "Alagoas" },
      { value: "AP", label: "Amapá" },
      { value: "AM", label: "Amazonas" },
      { value: "BA", label: "Bahia" },
      { value: "CE", label: "Ceará" },
      { value: "DF", label: "Distrito Federal" },
      { value: "ES", label: "Espírito Santo" },
      { value: "GO", label: "Goiás" },
      { value: "MA", label: "Maranhão" },
      { value: "MT", label: "Mato Grosso" },
      { value: "MS", label: "Mato Grosso do Sul" },
      { value: "MG", label: "Minas Gerais" },
      { value: "PA", label: "Pará" },
      { value: "PB", label: "Paraíba" },
      { value: "PR", label: "Paraná" },
      { value: "PE", label: "Pernambuco" },
      { value: "PI", label: "Piauí" },
      { value: "RJ", label: "Rio de Janeiro" },
      { value: "RN", label: "Rio Grande do Norte" },
      { value: "RS", label: "Rio Grande do Sul" },
      { value: "RO", label: "Rondônia" },
      { value: "RR", label: "Roraima" },
      { value: "SC", label: "Santa Catarina" },
      { value: "SP", label: "São Paulo" },
      { value: "SE", label: "Sergipe" },
      { value: "TO", label: "Tocantins" },
    ];
  }

  public static get jobs(): any {
    return [
      { value: "medico", label: "Médico(a)" },
      { value: "enfermeiro", label: "Enfermeiro(a)" },
      { value: "enfermagem", label: "Técnico em Enfermagem" },
      {
        value: "academicoultimo",
        label: "Acadêmico de medicina no último ano",
      },
      { value: "academico1a5", label: "Academico de medicina do 1º ao 5º ano" },
      { value: "estudante", label: "Estudante da área da saúde" },
      { value: "socorrista", label: "Socorrista" },
      { value: "dentista", label: "Dentista" },
      { value: "medicoresidente", label: "Médico(a) residente" },
      { value: "profissional", label: "Profissional da área da saúde" },
      { value: "outras", label: "Outras" },
    ];
  }

  public static get getCommunicationPhrases(): any {
    return [
      {
        value: 1,
        label:
          "Quero me manter informado(a) sobre cursos, conteúdos relevantes e benefícios exclusivos. Prometemos não enviar nenhum tipo de spam!",
      },
      {
        value: 2,
        label:
          "Quero me manter informado(a) sobre cursos, conteúdos relevantes e benefícios exclusivos.",
      },
      {
        value: 3,
        label:
          "Gostaria de receber informações de cursos com conteúdos e benefícios exclusivos por nossos canais de comunicação.",
      },
      {
        value: 4,
        label:
          "Clique aqui para receber informações de cursos com conteúdos e benefícios exclusivos por nossos canais de comunicação.",
      },
      {
        value: 5,
        label:
          "Clique aqui para receber informações dos Cursos e Benefícios Exclusivos.",
      },
    ];
  }

  public static get specializations(): any {
    return [
      { value: "acumpuntura", label: "Acumpuntura" },
      { value: "alergiaimunologia", label: " Alergia e imunologia" },
      { value: "anestesiologia", label: " Anestesiologia" },
      { value: "angiologia", label: " Angiologia" },
      { value: "cardiologia", label: " Cardiologia" },
      { value: "cirurgiacardiovascular", label: " Cirurgia cardiovascular" },
      { value: "cirurgiamao", label: " Cirurgia da mão" },
      { value: "cirurgiapescoco", label: " Cirurgia de cabeça e pescoço" },
      { value: "cirurgiadigestivo", label: " Cirurgia do aparelho digestivo" },
      { value: "cirurgiageral", label: " Cirurgia geral" },
      { value: "cirurgiaoncologica", label: " Cirurgia oncológica" },
      { value: "cirurgiapediatrica", label: " Cirurgia pediátrica" },
      { value: "cirurgiaplastica", label: " Cirurgia plástica" },
      { value: "cirurgiatoraxica", label: " Cirurgia torácica" },
      { value: "cirurgiavascular", label: " Cirurgia vascular" },
      { value: "clinicamedica", label: " Clínica médica" },
      { value: "coloproctologia", label: " Coloproctologia" },
      { value: "dermatologia", label: " Dermatologia" },
      {
        value: "endrocrinologiaemetabologia",
        label: " Endocrinologia e metabologia",
      },
      { value: "endoscopia", label: " Endoscopia" },
      { value: "gastroenterologia", label: " Gastroenterologia" },
      { value: "geneticamedica", label: " Genética médica" },
      { value: "geriatria", label: " Geriatria" },
      { value: "ginicologiaeobstetricia", label: " Ginecologia e obstetrícia" },
      { value: "hematologiahemoterapia", label: " Hematologia e hemoterapia" },
      { value: "homeopatia", label: " Homeopatia " },
      { value: "infectologia", label: " Infectologia" },
      { value: "mastologia", label: " Mastologia" },
      { value: "medicinaemergencia", label: " Medicina de emergência" },
      { value: "medicinafamilia", label: " Medicina de família e comunidade" },
      { value: "medicinatrabalho", label: " Medicina do trabalho" },
      { value: "medicinatrafego", label: " Medicina de tráfego" },
      { value: "medicinaesportiva", label: " Medicina esportiva" },
      { value: "medicinafisica", label: " Medicina física e reabilitação" },
      { value: "medicinaintensiva", label: " Medicina intensiva" },
      { value: "medicinalegal", label: " Medicina legal e perícia médica" },
      { value: "medicinanuclear", label: " Medicina nuclear" },
      { value: "medicinapreventiva", label: " Medicina preventiva e social" },
      { value: "nefrologia", label: " Nefrologia" },
      { value: "neurocirurgia", label: " Neurocirurgia" },
      { value: "neurologia", label: " Neurologia" },
      { value: "nutrologia", label: " Nutrologia" },
      { value: "oftalmologia", label: " Oftalmologia" },
      { value: "oncologiaclinica", label: " Oncologia clínica" },
      { value: "ortopediatraumatologia", label: " Ortopedia e traumatologia" },
      { value: "otorrinolaringo", label: " Otorrinolaringologia" },
      { value: "patologia", label: " Patologia" },
      {
        value: "medicinalaboratorial",
        label: " Patologia clínica/medicina laboratorial",
      },
      { value: "pediatria", label: " Pediatria" },
      { value: "pneumologia", label: " Pneumologia" },
      { value: "psiquiatria", label: " Psiquiatria" },
      {
        value: "radiologiaeimagem",
        label: " Radiologia e diagnóstico por imagem",
      },
      { value: "radioterapia", label: " Radioterapia" },
      { value: "reumatologia", label: " Reumatologia" },
      { value: "urologia", label: " Urologia" },
    ];
  }
  // public static get deliverOptions(): any {
  //   return [{
  //     label: 'SEDEX',
  //     value: 'sedex'
  //   },{
  //     label: 'PAC',
  //     value: 'pac'
  //   },{
  //     label: 'Retirar na unidade',
  //     value: 'other'
  //   }]
  // }

  public static get installmentFee(): any {
    return {
      1: 0,
      2: 0,
      3: 0,
      4: 1.49,
      5: 3.0,
      6: 4.54,
      7: 6.09,
      8: 7.68,
      9: 9.28,
      10: 10.91,
      11: 12.56,
      12: 14.24,
    };
  }

  constructor(@Inject(WINDOW) private window: Window) {}
}
