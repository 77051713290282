import { Component, Input } from "@angular/core";

@Component({
  selector: "cm-instructor-card",
  templateUrl: "./instructor-card.component.html",
  styleUrls: ["./instructor-card.component.scss"]
})
export class InstructorCardComponent {
  @Input() image: string;
  @Input() name: string;
  @Input() crm: string;
  @Input() courses: string;
  @Input() link: string;
}
