import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WheelComponent } from './pages/black-friday/wheel/wheel.component';
import { HomeComponent } from './pages/home/home.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { CoursesBlackComponent } from './pages/coursesBlack/coursesBlack.component';
import { ClassDetailsComponent } from './pages/class-details/class-details.component';
import { AssembleClassComponent } from './pages/assemble-class/assemble-class.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogPostComponent } from './pages/blog-post/blog-post.component';
import { LoginComponent } from './pages/login/login.component';
import { FaqComponent } from './pages/faq/faq.component';
import { InstructorsComponent } from './pages/instructors/instructors.component';
import { TalkToUsComponent } from './pages/talk-to-us/talk-to-us.component';
import { WorkWithUsComponent } from './pages/work-with-us/work-with-us.component';
import { UnitsComponent } from './pages/units/units.component';
import { CourseComponent } from './pages/course/course.component';
import { WhoWeEmpowerComponent } from './pages/who-we-empower/who-we-empower.component';
import { CheckoutResolver } from './pages/checkout/checkout.resolver';
import { ClassDetailsResolver } from './pages/class-details/class-details.resolver';
import { BlogPostResolver } from './pages/blog-post/blog-post.resolver';
import { WhoWeEmpowerResolver } from './pages/who-we-empower/who-we-empower.resolver';
import { CourseResolver } from './pages/course/course.resolver';
import { TermsComponent } from './pages/terms/terms.component';
import { TermsResolver } from './pages/terms/terms.resolver';
import { ErrorComponent } from './pages/error/error.component';
import { Constants } from 'src/app/shared/constants.service';
import { PostgraduateComponent } from './pages/postgraduate/postgraduate.component';
import { PostgraduateResolver } from './pages/postgraduate/postgraduate.resolver';
import { PosDetailsComponent } from './pages/pos-details/pos-details.component';
import { PoliticaCookiesComponent } from './pages/politica-cookies/politica-cookies.component';
import { PermissaoCookieComponent } from './shared/permissao-cookie/permissao-cookie.component';
import { SimsaveComponent } from './pages/simsave/simsave.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { CartComponent } from './pages/cart/cart.component';
import { CartCheckoutComponent } from './pages/cart-checkout/cart-checkout.component';


const websiteTitle = "Hammes -";
const routes: Routes = [

  {
    path: 'pos/:slug',
    component: PostgraduateComponent,
    data: {title: websiteTitle + ' Cursos'},
    resolve: {
      courseData: PostgraduateResolver
    }
  },
  {
    path: 'carrinho',
    component: CartComponent
  },
  {
    path: 'pos-sobre/:slug',
    component: PosDetailsComponent,
    data: {title: websiteTitle + ' Turmas'},
  },{
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  }, {
    path: 'home',
    component: HomeComponent,
    data: {title: websiteTitle + ' Home'}
  },{
    path: 'permissao-cookie',
    component: PermissaoCookieComponent,
    data: {title: websiteTitle + ' Permissão'}
  }, {
    path: 'politica-cookies',
    component: PoliticaCookiesComponent,
    data: {title: websiteTitle + ' Política de Cookies'}
  }, {
    path: 'politica-privacidade',
    component: PrivacyPolicyComponent,
    data: {title: websiteTitle + ' Política de Privacidade'}
  },{
    path: 'checkout/:slug',
    component: CheckoutComponent,
    data: {title: websiteTitle + ' Checkout'},
    resolve: {
      courseData: CheckoutResolver
    }
  },{
    path: 'checkout',
    component: CartCheckoutComponent,
    data: {title: websiteTitle + ' Checkout'},
  },{
    path: 'cursos',
    component: CoursesComponent,
    data: {title: websiteTitle + ' Cursos'}
  },{
    path: 'cursos-black',
    component: CoursesBlackComponent,
    data: {title: websiteTitle + ' Cursos'}
  },{
    path: 'turma/:slug',
    component: ClassDetailsComponent,
    data: {title: websiteTitle + ' Turmas'},
    resolve: {
      courseData: ClassDetailsResolver
    }
  },{
    path: 'curso/:slug',
    component: CourseComponent,
    data: {title: websiteTitle + ' Cursos'},
    resolve: {
      courseData: CourseResolver
    }
  },{
    path: 'turma-personalizada',
    component: AssembleClassComponent,
    data: {title: websiteTitle + ' Turma personalizada'}
  },{
    path: 'sobre-nos',
    component: AboutUsComponent,
    data: {title: websiteTitle + ' Sobre nós'}
  },{
    path: 'area-do-aluno',
    component: LoginComponent,
    data: {title: websiteTitle + ' Área do aluno'}
  },{
    path: 'faq',
    component: FaqComponent,
    data: {title: websiteTitle + ' FAQ'}
  },{
    path: 'instrutores',
    component: InstructorsComponent,
    data: {title: websiteTitle + ' Instrutores'}
  },{
    path: 'simsave',
    component: SimsaveComponent,
    data: {title: websiteTitle + ' SimSave'}
  },{
    path: 'fale-conosco',
    component: TalkToUsComponent,
    data: {title: websiteTitle + ' Fale Conosco'}
  },{
    path: 'trabalhe-conosco',
    component: WorkWithUsComponent,
    data: {title: websiteTitle + ' Trabalhe Conosco'}
  },{
    path: 'unidades',
    component: UnitsComponent,
    data: {title: websiteTitle + ' Unidades'}
  },{
    path: 'quem-capacitamos',
    component: WhoWeEmpowerComponent,
    data: {title: websiteTitle + ' Quem Capacitamos'},
    resolve: {
      clientsData: WhoWeEmpowerResolver
    }
  },{
    path: 'termos-de-compromisso/:id/:name',
    component: TermsComponent,
    data: {title: websiteTitle + ' Termos de Compromisso'},
    resolve: {
      termsData: TermsResolver
    }
  },{
    path: 'error',
    component: ErrorComponent
  },{
    path: '**',
    redirectTo: '/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
