import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cm-empowered-card',
  templateUrl: './empowered-card.component.html',
  styleUrls: ['./empowered-card.component.scss']
})
export class EmpoweredCardComponent {
  @Input() image: string;
  @Input() text: string;
  @Input() name: string;
  @Input() place: string;
  @Input() logo: string;
  @Input() video: string;

  @Output() getVideo = new EventEmitter();

  // ----- PUBLIC METHODS
  showVideo(): void {
    if(this.video != null && this.video != undefined && this.video.length > 0){
      this.getVideo.emit(this.video);
    }
  }
}
