import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// third party
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { InputMaskModule } from "primeng/inputmask";
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { CaptchaModule } from 'primeng/captcha';
//first party
import { SectionTitleComponent } from "./section-title/section-title.component";
import { CoursesPackageComponent } from "./courses-package/courses-package.component";
import { BlogCardComponent } from "./blog-card/blog-card.component";
import { CourseCardComponent } from "./course-card/course-card.component";
import { PageBannerComponent } from "./page-banner/page-banner.component";
import { SearchCourseBoxComponent } from "./search-course-box/search-course-box.component";
import { DoubtActionComponent } from "./doubt-action/doubt-action.component";
import { TestimonialComponent } from "./testimonial/testimonial.component";
import { UnitCardComponent } from "./unit-card/unit-card.component";
import { InstructorCardComponent } from "./instructor-card/instructor-card.component";
import { FormValidationComponent } from './form-validation/form-validation.component';
import { CourseFilterComponent } from './course-filter/course-filter.component';
import { ComplementaryCourseCardComponent } from './complementary-course-card/complementary-course-card.component';
import { StopClickPropagationModule } from '../directives/stop-click-propagation/stop-click-propagation.module';
import { EmpoweredCardComponent } from './empowered-card/empowered-card.component';
import { PermissaoCookieComponent } from './permissao-cookie/permissao-cookie.component';
import { LazyImgDirectiveModule } from "../directives/lazy-img-directive/lazy-img-directive.module";
import { CartItemCard } from './cart-item-card/cart-item-card.component';
import { ModalCrossDataComponent } from "./modal-cross-data/modal-cross-data.component";

@NgModule({
  declarations: [
    SectionTitleComponent,
    CoursesPackageComponent,
    BlogCardComponent,
    CourseCardComponent,
    PageBannerComponent,
    SearchCourseBoxComponent,
    DoubtActionComponent,
    TestimonialComponent,
    UnitCardComponent,
    InstructorCardComponent,
    FormValidationComponent,
    CourseFilterComponent,
    ComplementaryCourseCardComponent,
    EmpoweredCardComponent,
    PermissaoCookieComponent,
    CartItemCard,
    ModalCrossDataComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    CalendarModule,
    InputMaskModule,
    AutoCompleteModule,
    DropdownModule,
    CaptchaModule,
    StopClickPropagationModule,
    LazyImgDirectiveModule
  ],
  exports: [
    SectionTitleComponent,
    CoursesPackageComponent,
    BlogCardComponent,
    CourseCardComponent,
    PageBannerComponent,
    SearchCourseBoxComponent,
    DoubtActionComponent,
    TestimonialComponent,
    UnitCardComponent,
    InstructorCardComponent,
    FormValidationComponent,
    CourseFilterComponent,
    ComplementaryCourseCardComponent,
    EmpoweredCardComponent,
    PermissaoCookieComponent,
    CartItemCard,
    ModalCrossDataComponent
  ]
})
export class SharedModule {}
