import { Component, OnInit } from '@angular/core';
import { NgxWheelModule } from 'ngx-wheel';
import { CoreService } from 'src/app/core/core.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Constants} from '../../../shared/constants.service';
import { GenericValidator } from 'src/app/validators/cpf-validator';
import { SnotifyService } from 'ng-snotify';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from 'src/app/services/seo.service';
import { City } from 'src/app/interfaces/city';




@Component({
  selector: 'cm-wheel',
  templateUrl: './wheel.component.html',
  styleUrls: ['./wheel.component.scss'],

})

export class WheelComponent implements OnInit {

  isTerzius: boolean = false;
  cpfUsado: boolean = false;
  cpfError: boolean = false;
  termsAccepted: boolean = false;
  form: FormGroup;
  listaCpf:any = ['104.630.986-25',
  '934.552.180-16'];
  mensagemCpf:string;
  mensagemVoucher: string;
  mensagemGeral: string;
  voucherError: boolean = false;
  genericError: boolean;
  private _cities: any;
  private _selectedCityId: any;
  private _selectedUfId: any;
  citiesNames: any;
  cityName: any;






  constructor(
    private _formBuilder: FormBuilder,
    private _coreService: CoreService
  ) { }

  ngOnInit() {
    Constants.isTerzius?this.isTerzius= true:false;
    this.form = this._formBuilder.group({
      name: [null, [Validators.required]],
      cpf: [null, [Validators.required, GenericValidator.isValidCpf()]],
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required]],
      city_id: [null, [Validators.required]],
      token: [null, [Validators.required]],
    });


  }

  searchCity(event: any): void {
    let params = {'search_text': event.query};
    this._coreService.getCities(params).subscribe(response => {
      this._cities = response;
      this.citiesNames = response.map(city => city.name + ` - `+ city.uf);
    })
  }

  defineCity(cityName: any): void {
    this._cities.find(city => {
      let cuf = city.name + " - " + city.uf;
      if(cuf === cityName){
        this._selectedCityId = city.id;
        this.cityName = cityName;
        this._selectedUfId = city.uf_id;
        return true;
      }
    })


  }

  captchaResponse(event): void {
    this.form.patchValue({token: event.response});
  }

  captchaExpires(): void {
    this.form.patchValue({token: null});
  }

  cpf:string = '';
  nome:'';
  telefone:'';
  email:'';
  cidade:'';
  range: {
    10,
    99
  }
  erroEmail = false;
  emailvalidado = false;
  enviandoFormulario= false;
  formularioOk= false;
  messageWin = '';
  prize = "50% Curem";
  enviado = false;
  roleta= false;
  voucher = '';


  definePrmiacao(){
    this.messageWin = 'Você ganhou um voucher de '+ this.prize + ' :';
    this.roleta = false;

  }

  checkaFormulario(){
    if(this.email.length > 8 && this.nome.length > 3 && this.telefone.length > 9 && this.email.indexOf('@')!=-1 && this.email.indexOf('.com')!=-1 && this.isValidCPF(this.cpf) ){
      this.cpfError = true;
      this.formularioOk = true;
    }else{
      this.formularioOk=false;
    }
  }

  acceptTerms(value: boolean): void {
    this.termsAccepted = value;
  }

enviarDados(){

  let data = {
    cpf: this.form.value.cpf,
    nome: this.form.value.name,
    telefone: this.form.value.phone,
    email: this.form.value.email,
    captcha: this.form.value.token,
    cidade:  this.cityName,
    aceptTerms: this.termsAccepted
  }

  this._coreService.getBlackFridayVoucher(data).subscribe((response: any) => {

    this.enviandoFormulario = true;
    if(response.success == 'true'){
      this.erroEmail = false;
      this.enviado = true;
      this.roleta = true;
      this.prize = response.desconto+ "% "+ response.tipo;
      this.voucher = response.voucher;
    }else{
      if(response.type == 'cpf'){
        this.cpfError = true;
        this.mensagemCpf = "Você pode girar a roleta apenas uma vez com este CPF.";
      }
      if(response.type == 'voucher'){
        this.voucherError = true;
        this.mensagemVoucher = "Todos os vouchers foram sorteados.";
      }else{
        this.genericError = true;
        this.mensagemGeral = 'Erro desconhecido. Tente novamente mais tarde, ou entre em contato conosco';
      }
    }
    this.enviandoFormulario = false;
  })
}



 isValidCPF(cpf) {
  if (typeof cpf !== "string") return false
  cpf = cpf.replace(/[\s.-]*/igm, '')
  if (cpf.length !== 11 || !Array.from(cpf).filter(e => e !== cpf[0]).length) {
    return false
  }
  var soma = 0
  var resto
  for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
  resto = (soma * 10) % 11
  if ((resto == 10) || (resto == 11))  resto = 0
  if (resto != parseInt(cpf.substring(9, 10)) ) return false
  soma = 0
  for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
  resto = (soma * 10) % 11
  if ((resto == 10) || (resto == 11))  resto = 0
  if (resto != parseInt(cpf.substring(10, 11) ) ) return false
  return true
}

verificaCpf(){

  if (this.listaCpf.indexOf(this.form.value.cpf)!=-1){
    return false;
  }else{
    return true;

  }
}



}
