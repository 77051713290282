import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { CoreService } from 'src/app/core/core.service';
import { Course } from 'src/app/interfaces/course';
import { SeoService } from 'src/app/services/seo.service';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'cm-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss']
})
export class CoursesComponent implements OnInit {
  searchParams: any = {};
  mobileFilters: boolean = false;
  courses: Course[] = [];
  comboCourses: Course[] = [];
  coursesView: Course[] = [];
  activeTab: string = 'individual';
  banner: string = '';

  totalRecords: number = 0;
  totalRecordsIndividual: number = 0;
  totalRecordsCombo: number = 0;
  perPageRecords: number = 10;

  constructor(@Inject(WINDOW) private window: Window,
    private _coreService: CoreService,
    private _seoService: SeoService,
    private _activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this._getBanner();
    this._activatedRoute.queryParams.subscribe(params => {
      let newParams = {...params}
      if(newParams.hasOwnProperty('course_type_ids')){
        newParams['course_type_ids'] = [parseInt(newParams['course_type_ids'])]
      }

      if(newParams.hasOwnProperty('city_id')){
        newParams['city_id'] = [parseInt(newParams['city_id'])]
      }

      this.searchParams = newParams;
      this._findCourses();
    });

    this._activatedRoute.url.subscribe((url: any) => {
      let path = '';
      url.map(item => path += '/' + item.path);
      path = path.substring(1);
      this._seoService.defineTags(path);
    });
  }

  // ----- PUBLIC METHODS
  toggleMobileFilters(): void {
    this.mobileFilters = !this.mobileFilters;
  }

  paginate(event) {
    let params = {};
    params['page'] = event.page + 1 + '';
    params['per_page'] = this.perPageRecords.toString();
    this._findCourses(params);
  }

  showIndividual() {
    this.activeTab = 'individual';
    this.coursesView = this.courses;
    this.totalRecords = this.totalRecordsIndividual;
  }

  showCombo() {
    this.activeTab = 'combo';
    this.coursesView = this.comboCourses;
    this.totalRecords = this.totalRecordsCombo;
  }

  getParameters(e){
    this.searchParams = e;
    this._coreService.searchCourses(e).subscribe((response: any) => {
      this.totalRecordsIndividual = response.total;
      this.courses = response.data;
      this.mobileFilters = false;

      if(this.activeTab == 'individual') {
        this.coursesView = this.courses;
        this.totalRecords = response.total;
      }

      e.only_packages = true;
      this._coreService.searchCourses(e).subscribe((response: any) => {
        this.totalRecordsCombo = response.total;
        this.comboCourses = response.data;

        if(this.activeTab == 'combo') {
          this.coursesView = this.comboCourses;
          this.totalRecords = response.total;
        }

        this.window.scrollTo(0,200);
      })

    })
  }

  // ----- PRIVATE METHODS
  private _findCourses(data = {}): void {
    let params = {...this.searchParams, ...data};
    params.only_packages = false;
    this._coreService.searchCourses(params).subscribe((response: any) => {
      this.courses = response.data;
      this.totalRecordsIndividual = response.total;

      if(this.activeTab == 'individual') {
        this.coursesView = this.courses;
        this.totalRecords = response.total;
        params.page = 1;
      }

      params.only_packages = true;
      this._coreService.searchCourses(params).subscribe((response: any) => {
        this.comboCourses = response.data;
        this.totalRecordsCombo = response.total;

        if(this.activeTab == 'combo') {
          this.coursesView = this.comboCourses;
          this.totalRecords = response.total;
        }
      })
    })
  }

  private _getBanner(): void {
    const params = {page: 'cursos'};
    this._coreService.getPageBanner(params).subscribe((response: any) => {
      this.banner = response.image_url;
    })
  }
}
